import {
  Iteration,
  Section,
  SectionKey,
} from '../../failed-check-details/context';
import { djb2Hash, isEmpty, toCapitalize, toUnderscore } from 'shared/utils';
import { ExecutedCommand, ReportTableDataItem } from '../models';
import { Node } from 'components/tree';
import {
  app__schemas__dto__checks__CheckResult,
  Check,
  LLMResult,
} from 'shared/api/client';
import {
  propsBy,
  titleIteration,
} from 'pages/reports/failed-check-details/contant';

export function getTop(
  objects: ReportTableDataItem[],
  field: keyof ReportTableDataItem,
  topCount: number,
): { name: string; count: number }[] {
  const counts: { [key: string]: number } = {};

  (objects || [])
    .filter((x) => x.status === 'FAIL')
    .forEach((object) => {
      const data = object[field];
      if (data) {
        counts[data] = (counts[data] || 0) + 1;
      }
    });

  const arr = Object.keys(counts).map((namespace) => ({
    name: namespace,
    count: counts[namespace],
  }));

  arr.sort((a, b) => b.count - a.count);

  return arr.slice(0, topCount);
}

export const mapCheckResults = (
  checkResults: app__schemas__dto__checks__CheckResult[],
  checkList: Record<string, Check>,
): ReportTableDataItem[] => {
  return (checkResults || [])?.map(
    (x) =>
      ({
        object: x?.object_name,
        resourceType: x?.object_meta?.resource_type,
        namespace: x?.object_meta?.resource_namespace,
        checkId: x?.check_id,
        checkResultId: x?.id,
        executionId: x?.execution_id,
        check: ((checkList || {})[x?.check_id] || {})?.title ?? x?.check_id,
        status: x.status,
        severity: toCapitalize(
          ((checkList || {})[x?.check_id] || {})?.severity?.toLowerCase(),
        ),
        result: toCapitalize((x?.status || '')?.toLowerCase()),
        explanation: x?.explanation,
        explainWithAI: x?.ai_explanation,
      }) as ReportTableDataItem,
  );
};

export function mapRecommendations(
  recommendations: LLMResult[],
  executedCommands: Record<string, ExecutedCommand>,
) {
  return (recommendations || [])?.reduce(
    (rec: Record<string, Iteration>, rItem, index) => {
      const keyIteration = `${titleIteration}${index > 0 ? ' ' + (index + 1) : ''}`;
      rec[keyIteration] = {
        id: rItem?.id,
        isExpanded: true,
        data: {
          Summary: {
            type: 'summary',
            isExpanded: true,
            value: rItem?.recommendation?.summary || '',
          },
          'Possible diagnostics': {
            type: 'commands',
            isExpanded: true,
            commands:
              rItem?.recommendation?.diagnostics?.reduce(
                (
                  pd: Record<string, ExecutedCommand | null>,
                  pdItem,
                  curIndex,
                ) => {
                  pd[pdItem] =
                    { ...executedCommands[pdItem], order: curIndex } || null;
                  return pd;
                },
                {},
              ) || {},
          },
          'Possible fixes': {
            type: 'commands',
            isExpanded: true,
            commands:
              rItem?.recommendation?.fixes?.reduce(
                (
                  pf: Record<string, ExecutedCommand | null>,
                  pfItem,
                  curIndex,
                ) => {
                  pf[pfItem] =
                    { ...executedCommands[pfItem], order: curIndex } || null;
                  return pf;
                },
                {},
              ) || {},
          },
        },
      };
      return rec;
    },
    {},
  );
}

export function mapIterationToTree<T, K = keyof T>(
  data: { [key in SectionKey]?: Section },
  key: K,
): Node {
  const children: Node[] = [];
  if (!data) return { isLoading: true };

  for (const k in data) {
    const sections = typeof data === 'object' ? data : {};
    const section = (sections || {})[k as SectionKey];
    const childrenCommands: Node[] = [];
    if (section?.type === 'commands') {
      //&& (k as SectionKey) !== 'Diagnostics'
      Object.entries(section?.commands || {}).forEach(([kC, vC]) => {
        // if (vC?.output) {
        const itemKey = vC?.id || djb2Hash(kC);
        childrenCommands.push({
          id: toUnderscore(`${key}-${k}-${itemKey}`),
          key: toUnderscore(`${key}-${k}-${itemKey}`),
          //iconProps: propsBy[k]?.iconProps,
          label: kC,
          data: {
            iterationKey: key as string,
            sectionKey: k,
            commandId: vC?.id,
            command: kC,
          },
          isLoading: false,
          className: 'command',
        } as Node);
        // }
      });
    }

    children.push({
      id: toUnderscore(`${key}-${k}`),
      key: toUnderscore(`${key}-${k}`),
      iconProps: propsBy[k]?.iconProps,
      data: {
        iterationKey: key as string,
        sectionKey: k as SectionKey,
      },
      label: k,
      isLoading: false,
      children: childrenCommands?.length > 0 ? childrenCommands : undefined,
    });
  }

  return {
    id: toUnderscore(String(key)),
    key: toUnderscore(String(key)),
    label: String(key),
    data: {
      iterationKey: key as string,
    },
    iconProps: propsBy[String(key)]?.iconProps,
    children,
    isLoading: isEmpty(data),
  };
}

export function filterCommandsByCondition(
  commands: Record<string, ExecutedCommand> | undefined,
  condition: (command: ExecutedCommand) => boolean,
): Record<string, ExecutedCommand> | undefined {
  if (!commands) {
    // If the input is undefined, return undefined
    return undefined;
  }
  // Create a new object to hold filtered commands
  const filteredCommands: Record<string, ExecutedCommand> = {};

  // Iterate over each entry in the commands object
  for (const key in commands) {
    if (commands.hasOwnProperty(key)) {
      const command = commands[key];

      // Check if the command meets the condition
      if (condition(command)) {
        // If it meets the condition, add it to the filteredCommands object
        filteredCommands[key] = command;
      }
    }
  }

  // Return the filtered commands
  return filteredCommands;
}
