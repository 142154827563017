import React from 'react';
import { Node, TreeView } from 'components';

import { useCheckDetails } from './context';
import { mapIterationToTree } from '../shared/utils';
import { useNavigate } from 'react-router';
import { TreeNodeClickEvent } from 'primereact/tree';

interface TroubleshootTabViewProps {
  currentIteration: string;
  setCurrentIteration: React.Dispatch<React.SetStateAction<string>>;
}

export const TroubleshootTabView = React.memo(
  ({ currentIteration, setCurrentIteration }: TroubleshootTabViewProps) => {
    const { iterations, updateIteration } = useCheckDetails();
    const navigate = useNavigate();

    const onNodeClickHandler =
      (newValue: string, rootId?: string | null) => (e: TreeNodeClickEvent) => {
        const id = e.node.id;
        setCurrentIteration(newValue);
        const nodeData: Node['data'] = e.node.data;

        if (nodeData?.iterationKey) {
          const iteration = {
            ...(iterations[nodeData?.iterationKey] || {}),
          };
          iteration.isExpanded = true;
          const sections = iteration.data || {};
          if (nodeData?.sectionKey) {
            const currentSections = sections[nodeData?.sectionKey];
            if (currentSections) {
              currentSections.isExpanded = true;
            }
          }

          if (nodeData?.iterationKey) {
            updateIteration(nodeData?.iterationKey, {
              ...iteration,
            });
          }
        }
        setTimeout(() => {
          navigate({ hash: `#${id}` });
        }, 650);
      };

    return (
      <>
        {Object.entries(iterations).map(([key, iteration], index) => {
          const nodes = [mapIterationToTree(iteration?.data || {}, key)];
          return (
            <TreeView
              onNodeClick={onNodeClickHandler(key, iteration?.id)}
              isActive={currentIteration === key}
              key={`${key}_${index}`}
              nodes={nodes}
            />
          );
        })}
      </>
    );
  },
);
TroubleshootTabView.displayName = 'TroubleshootTabView';
