import { ExplainWithAIBanner } from 'components/banner';
import { Button } from 'primereact/button';
import React from 'react';
import { NavigateFunction } from 'react-router';
import { ChecksService } from 'shared/api/client';
import { useDialog } from 'shared/providers/dialog/context';
import { useToast } from 'shared/providers/toast/context';
import { toUnderscore } from 'shared/utils';
import { titleIteration } from '../failed-check-details/contant';
import { Iteration, SectionKey } from '../failed-check-details/context';
import { ReportModel } from '../shared/models';
import { mapRecommendations } from '../shared/utils';
import styles from './styles.module.scss';

export const RequestRecommendationsModal = () => {
  return (
    <div className={styles.reqRecommendationsModal}>
      <p className={styles.content}>
        Are you sure you want request new recommendations? We will send all
        output to LLM and create recommendations for you.
      </p>
    </div>
  );
};

interface RequestRecommendationsModalFooterProps {
  executionId?: string;
  checkResultId?: string;
  iterations: Record<string, Iteration>;
  navigate: NavigateFunction;
  updateReport: (
    key: string,
    data: ReportModel | ((prev: ReportModel) => ReportModel),
  ) => void;
  addIteration: (key: SectionKey, data: Iteration) => void;
  removeIteration: (key: SectionKey) => void;
  addIterations: (data: Record<string, Iteration>) => void;
  setCurrentIteration: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: boolean;
        }
      | undefined
    >
  >;
}

export const RequestRecommendationsModalFooter = ({
  executionId,
  checkResultId,
  iterations,
  navigate,
  addIteration,
  updateReport,
  removeIteration,
  addIterations,
  setCurrentIteration,
  setLoading,
}: RequestRecommendationsModalFooterProps) => {
  const { openDialog, closeDialog } = useDialog();
  const { showApiError } = useToast();

  const onClickExplainWithAIHandler = () => {
    closeDialog();
    const countIterations = Object.keys(iterations || {})?.length;
    const keyIteration = `${titleIteration}${countIterations > 1 ? ' ' + countIterations : ''}`;
    if (executionId && checkResultId) {
      setLoading((prev) => ({ ...prev, [keyIteration]: true }));
      addIteration(keyIteration as SectionKey, { isExpanded: true });
      setCurrentIteration(keyIteration);
      ChecksService.aiExplainApiV1ChecksCheckResultIdExplainPost({
        checkResultId,
      })
        .then((res) => {
          if (res?.recommendations) {
            addIterations(
              mapRecommendations(
                res?.recommendations || [],
                res.executed_commands || {},
              ),
            );
            if (res?.ai_explanation) {
              updateReport(executionId, (prev) => ({
                ...prev,
                items: {
                  ...prev.items,
                  [checkResultId]: {
                    ...(prev?.items || {})[checkResultId],
                    explainWithAI: res?.ai_explanation || undefined,
                  },
                },
              }));
            }

            navigate({ hash: `#${toUnderscore(keyIteration)}` });
          } else {
            removeIteration(keyIteration as SectionKey);
          }
          setLoading((prev) => ({ ...prev, [keyIteration]: false }));
        })
        .catch((e) => {
          removeIteration(keyIteration as SectionKey);
          setLoading((prev) => ({ ...prev, [keyIteration]: false }));
          if (e?.status === 403) {
            openDialog({ content: <ExplainWithAIBanner /> });
          } else {
            showApiError(e);
          }
        });
    }
  };

  return (
    <div className={styles.reqRecommendationsModalFooter}>
      <Button onClick={onClickExplainWithAIHandler}>
        Request recommendations
      </Button>
      <Button onClick={closeDialog} severity="secondary">
        Cancel
      </Button>
    </div>
  );
};
