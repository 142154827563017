import { createContext, useContext } from 'react';
import { SectionKey } from '.';

export interface Options {
  iterationId: string | null | undefined;
  iterationKey: string;
  sectionId: SectionKey;
  commandId: string | null | undefined;
  command: string;
  mode: 'edit' | 'add';
}

export interface EditCommandContextType {
  loading: boolean;
  inputValue: {
    [key: string]: {
      [key in SectionKey]: {
        [key: string]: string;
      };
    };
  };
  inputType: {
    name: string;
    code: string;
  };
  setInputValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        [key in SectionKey]: {
          [key: string]: string;
        };
      };
    }>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSent: ({
    iterationId,
    iterationKey,
    sectionId,
    command,
    commandId,
  }: Options) => void;
  onConfirm: ({
    iterationId,
    sectionId,
    iterationKey,
    commandId,
    command,
    mode,
  }: Options) => void;
  setInputType: React.Dispatch<
    React.SetStateAction<{
      name: string;
      code: string;
    }>
  >;
}

export const EditCommandContext = createContext<EditCommandContextType>(
  {} as EditCommandContextType,
);

export const useEditCommand = () => {
  const context = useContext(EditCommandContext);
  if (!context) {
    throw new Error('useEditCommand must be used within a EditCommandProvider');
  }
  return context;
};
