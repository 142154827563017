import { Severity, SeverityProps } from 'components';
import { ExplanationColumnCell } from '../../current-report/explanation-column-cell';
import React from 'react';
import { ReportTableDataItem } from '../models';
import { DataGridColumns } from 'components/data-grid';
import { severityOrder } from './constants';

export const reportColumns: DataGridColumns<ReportTableDataItem> = [
  {
    filter: true,
    options: {
      header: 'Object',
      field: 'object',
      style: {
        width: '20%',
      },
      sortable: true,
      filterField: 'object',
    },
  },
  {
    filter: true,
    options: {
      header: 'Resource Type',
      field: 'resourceType',
      sortable: true,
      filterField: 'resourceType',
      style: {
        width: '10%',
      },
    },
  },
  {
    filter: true,
    options: {
      header: 'Namespace',
      field: 'namespace',
      sortable: true,
      filterField: 'namespace',
      style: {
        width: '10%',
      },
    },
  },
  {
    filter: true,
    options: {
      header: 'Check',
      field: 'check',
      sortable: true,
      filterField: 'check',
      style: {
        width: '20%',
      },
    },
  },
  // {
  //   filter: true,
  //   options: {
  //     header: 'Result',
  //     field: 'result',
  //     sortable: true,
  //     filterField: 'result',
  //     body: (row: ReportTableDataItem) => {
  //       return <ResultStatus type={row?.status}>{row?.result}</ResultStatus>;
  //     },
  //     style: {
  //       width: '8%',
  //       minWidth: '8rem',
  //     },
  //   },
  // },
  {
    filter: true,
    options: {
      header: 'Severity',
      field: 'severity',
      sortable: true,

      filterField: 'severity',
      sortOptionsInFilter: (a, b) => {
        const severityIndexA = severityOrder.indexOf(a || '');
        const severityIndexB = severityOrder.indexOf(b || '');
        if (severityIndexA < severityIndexB) {
          return -1;
        } else if (severityIndexA > severityIndexB) {
          return 1;
        } else {
          return 0;
        }
      },
      sortFunction: (e) => {
        const value = [...(e.data || [])] as ReportTableDataItem[];
        (value || []).sort((a, b) => {
          const severityIndexA = severityOrder.indexOf(a.severity || '');
          const severityIndexB = severityOrder.indexOf(b.severity || '');
          if (severityIndexA < severityIndexB) {
            return e.order === 1 ? -1 : 1;
          } else if (severityIndexA > severityIndexB) {
            return e.order === 1 ? 1 : -1;
          } else {
            return 0;
          }
        });

        return value;
      },
      body: (row: ReportTableDataItem) => {
        return (
          <Severity
            type={(row?.severity || '')?.toLowerCase() as SeverityProps['type']}
          />
        );
      },
      style: {
        width: '8%',
        minWidth: '8rem',
      },
    },
  },
  {
    options: {
      header: 'Explanation',
      field: 'explanation',
      body: (row: ReportTableDataItem) => {
        return <ExplanationColumnCell row={row} />;
      },
      style: {
        width: '35%',
        minWidth: '12rem',
      },
    },
  },
];
