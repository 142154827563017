import React from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePageData } from 'router/shared/models';

const Home = () => {
  return <Navigate to="/reports/last" replace />;
};

export default {
  route: { Component: Home, path: '/' },
  wrapToPage: false,
  pageStyle: 'summary',
} as RoutePageData;
