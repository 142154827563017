import { PageManager, RoutePage } from './shared/utils';
import HomePage from 'pages/home';
import ReportsOverviewPage from 'pages/reports';
import ReportPage from 'pages/reports/current-report';
import FailedCheckDetailsPage from 'pages/reports/failed-check-details';

export const PAGE_MANAGER = new PageManager(false, {
  root: {
    index: HomePage,
    reports: {
      index: ReportsOverviewPage,
      report: {
        index: ReportPage,
        failedCheckDetails: FailedCheckDetailsPage,
      },
    },
  },
});

export const pages: RoutePage[] = PAGE_MANAGER.getPages();
