import React from 'react';
import { Accordion, AccordionItem, Icon, LoadingDots } from 'components';
import {
  CommandSection,
  Iteration,
  SectionKey,
  useCheckDetails,
} from './context';
import { propsBy } from './contant';
import { Commands } from './commands';
import { copyToClipboard, toUnderscore } from 'shared/utils';
import styles from './styles.module.scss';
import cx from 'classnames';
import { useToast } from 'shared/providers/toast/context';
import { AccordionTabChangeEvent } from 'primereact/accordion';
import { ExecutedCommand } from '../shared/models';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChecksService, CheckStatus } from 'shared/api/client';
import { useParams } from 'react-router';
import { LoadingAccordingList } from './loading';
import { Button } from 'primereact/button';

interface AccordionsListProps {
  iterations: Record<string, Iteration>;
  status?: CheckStatus;
  activeIndexes: {
    [key: string]: number | number[] | null;
  } | null;
  onTabChangeHandler: (key: string) => (event: AccordionTabChangeEvent) => void;
}

interface SectionHeaderProps {
  title?: string;
  isExecuting: boolean;
}

const SectionHeader = ({ title, isExecuting }: SectionHeaderProps) => {
  return (
    <span className={styles.headerSection}>
      <span className={cx(styles.title)}>{title}</span>
      {isExecuting && (
        <span className={styles.isExecuting}>
          <span className={styles.spinner}>
            <ProgressSpinner
              style={{
                width: '1.5rem',
                height: '1.5rem',
                margin: 0,
              }}
            />
          </span>
          Executing commands
          <LoadingDots />
        </span>
      )}
    </span>
  );
};

interface Params extends Record<string, string> {
  executionId: string;
  checkResultId: string;
}

export const AccordionsList = ({
  iterations,
  status,
  activeIndexes,
  onTabChangeHandler,
}: AccordionsListProps) => {
  const { showToast } = useToast();
  const { updateData } = useCheckDetails();
  const { checkResultId, executionId } = useParams<Params>();

  const ignoreCommandHandler =
    (
      iterId: string,
      iterData: Iteration,
      sectionId: SectionKey,
      commands: string | Record<string, ExecutedCommand | null>,
      data: AccordionItem<ExecutedCommand>,
    ) =>
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      updateData((prev) => {
        const iteration = { ...((prev?.iterations || {})[iterId] || {}) };
        const sections = { ...(iteration?.data || {}) };
        const section = sections[sectionId];

        if (section?.type === 'commands') {
          section.commands = {
            ...(section.commands || {}),
            [data?.command]: {
              ...((section.commands || {})[data?.command] || {}),
              isIgnored: !((section.commands || {})[data?.command] || {})
                ?.isIgnored,
            },
          };
        }

        return {
          ...prev,
          iterations: {
            ...prev?.iterations,
            [iterId]: {
              ...iteration,
              data: {
                ...sections,
                [sectionId]: section,
              },
            },
          },
        };
      });
    };

  const editCommandHandler =
    (
      iterId: string,
      iterData: Iteration,
      sectionId: SectionKey,
      commands: Record<string, ExecutedCommand | null>,
      data: AccordionItem<ExecutedCommand>,
    ) =>
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      updateData((prev) => {
        const iteration = { ...((prev?.iterations || {})[iterId] || {}) };
        const sections = { ...(iteration?.data || {}) };
        const section = sections[sectionId];
        if (section?.type === 'commands') {
          section.commands = {
            ...(section.commands || {}),
            [data?.command]: {
              ...((section.commands || {})[data?.command] || {}),
              isEditing: true,
            },
          };
        }

        return {
          ...prev,
          iterations: {
            ...prev?.iterations,
            [iterId]: {
              ...iteration,
              data: {
                ...sections,
                [sectionId]: section,
              },
            },
          },
        };
      });
    };

  const executeAllCommandHandler =
    (
      iterId: string,
      iterData: Iteration,
      sectionId: SectionKey,
      commands: Record<string, ExecutedCommand | null>,
    ) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      updateData((prev) => {
        const iteration = { ...((prev?.iterations || {})[iterId] || {}) };
        const sections = { ...(iteration?.data || {}) };
        const section = sections[sectionId];

        if (section?.type === 'commands') {
          Object.keys(section.commands).forEach((key) => {
            const command = { ...(section.commands[key] || {}) };
            if (!command?.isIgnored) {
              command['isExecuting'] = true;
            }
            section.commands[key] = command;
          });
        }
        return {
          ...prev,
          iterations: {
            ...prev?.iterations,
            [iterId]: {
              ...iteration,
              data: {
                ...sections,
                [sectionId]: section,
              },
            },
          },
        };
      });
      if (checkResultId && executionId && iterData?.id) {
        const reqCommandsObj = Object.entries(commands)
          .filter(([kC, vC]) => !vC?.isIgnored)
          .filter(([kC, vC]) => !vC?.isNew);

        const reqCommands = Object.values(reqCommandsObj || {})?.map(
          ([kV]) => kV,
        );
        if (reqCommands?.length > 0) {
          ChecksService.runCommandsApiV1ChecksCheckResultIdCommandsPost({
            checkResultId,
            requestBody: {
              check_result_id: checkResultId,
              recommendation_id: iterData?.id,
              commands: typeof commands === 'string' ? [commands] : reqCommands,
            },
          }).then((res) => {
            updateData((prev) => {
              const iteration = { ...((prev?.iterations || {})[iterId] || {}) };
              const sections = { ...(iteration?.data || {}) };
              const section = sections[sectionId];

              if (section?.type === 'commands') {
                Object.keys(section.commands).forEach((key) => {
                  const command = { ...(section.commands[key] || {}) };
                  command['isExecuting'] = false;
                  command['isExpanded'] = true;
                  command['output'] = res.outputs[key] ?? null;
                  section.commands[key] = command;
                });
              }

              return {
                ...prev,
                iterations: {
                  ...prev?.iterations,
                  [iterId]: {
                    ...iteration,
                    data: {
                      ...sections,
                      [sectionId]: section,
                    },
                  },
                },
              };
            });
          });
        }
      }
    };

  const executeCommandHandler =
    (
      iterId: string,
      iterData: Iteration,
      sectionId: SectionKey,
      commands: string | Record<string, ExecutedCommand | null>,
      data: AccordionItem<ExecutedCommand>,
    ) =>
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      updateData((prev) => {
        const iteration = { ...((prev?.iterations || {})[iterId] || {}) };
        const sections = { ...(iteration?.data || {}) };
        const section = sections[sectionId];

        if (section?.type === 'commands') {
          section.commands[data?.command] = {
            ...(section.commands[data?.command] || {}),
            isExecuting: true,
          };
        }

        return {
          ...prev,
          iterations: {
            ...prev?.iterations,
            [iterId]: {
              ...iteration,
              data: {
                ...sections,
                [sectionId]: section,
              },
            },
          },
        };
      });
      if (checkResultId && executionId && iterData?.id) {
        ChecksService.runCommandsApiV1ChecksCheckResultIdCommandsPost({
          checkResultId,
          requestBody: {
            check_result_id: checkResultId,
            recommendation_id: iterData?.id,
            commands: [data?.command || ''],
          },
        }).then((res) => {
          updateData((prev) => {
            const iteration = { ...((prev?.iterations || {})[iterId] || {}) };
            const sections = { ...(iteration?.data || {}) };
            const section = sections[sectionId];

            if (section?.type === 'commands') {
              section.commands = {
                ...(section.commands || {}),
                [data?.command]: {
                  ...section.commands[data.command],
                  output: res.outputs[data?.command] ?? null,
                  isExecuting: false,
                  isExpanded: true,
                },
              };
            }

            return {
              ...prev,
              iterations: {
                ...prev?.iterations,
                [iterId]: {
                  ...iteration,
                  data: {
                    ...(iteration?.data || {}),
                    [sectionId]: section,
                  },
                },
              },
            };
          });
        });
      }
    };

  const onTabRootChangeHandler = (
    event: AccordionTabChangeEvent & {
      tab: AccordionItem<Iteration>;
      selected: boolean;
    },
  ) => {
    onTabChangeHandler('root')(event);
    updateData((prev) => {
      const iterId = event?.tab?.key;

      if (iterId) {
        return {
          ...prev,
          iterations: {
            ...prev?.iterations,
            [iterId]: {
              ...((prev?.iterations || {})[iterId] || {}),
              isExpanded: event.selected,
            },
          },
        };
      }
      return prev;
    });
  };

  const onTabSectionChangeHandler =
    (iterationKey: string) =>
    (
      event: AccordionTabChangeEvent & {
        tab: AccordionItem<ExecutedCommand>;
        selected: boolean;
      },
    ) => {
      onTabChangeHandler(iterationKey)(event);
      updateData((prev) => {
        const key = event?.tab?.key as SectionKey;
        const iteration = { ...((prev?.iterations || {})[iterationKey] || {}) };
        const sections = { ...(iteration?.data || {}) };
        const section = sections[key];

        if (section) {
          section.isExpanded = event.selected;
        }

        return {
          ...prev,
          iterations: {
            ...prev?.iterations,
            [iterationKey]: {
              ...iteration,
              data: {
                ...sections,
                [key]: section,
              },
            },
          },
        };
      });
    };

  const iterrationItems: AccordionItem<Iteration>[] = [];
  Object.entries(iterations || {}).forEach(([rk, value]) => {
    const childItems = Object.entries(value?.data || {}).map(([k, v], i) => {
      const id = toUnderscore(`${rk}-${k}`);
      return {
        id: id,
        data: v,
        key: k,
        isExpanded: v?.isExpanded,
        header: (
          <SectionHeader
            isExecuting={Object.entries(
              (v as CommandSection).commands || {},
            ).some(([_, scv]) => scv?.isExecuting)}
            title={k}
          />
        ),
        actions: [
          {
            id: 'executeAll',
            hide: () => {
              return (
                status === 'PASS' ||
                (k as SectionKey) === 'Diagnostics' ||
                (k as SectionKey) === 'Summary'
              );
            },
            disabled: ({ command }) =>
              Object.entries((v as CommandSection)?.commands || {}).every(
                ([kC, vK]) => !!(vK as ExecutedCommand)?.isIgnored,
              ),
            element: (data) => (
              <Button
                onClick={executeAllCommandHandler(
                  rk,
                  value,
                  k as SectionKey,
                  ((v || {}) as CommandSection)?.commands,
                )}
                severity="secondary"
              >
                <Icon size={'1.5rem'} name="execute" />
                Execute All
              </Button>
            ),
          },
        ],
        ...propsBy[k],
        content:
          v.type === 'commands' ? (
            <Commands
              rootKey={id}
              iterationKey={rk}
              iterationId={value?.id}
              sectionKey={k as SectionKey}
              actions={[
                {
                  id: 'edit',
                  hide: () => {
                    return (
                      status === 'PASS' || (k as SectionKey) === 'Diagnostics'
                    );
                  },
                  element: (data) => (
                    <div className={styles.headerActionCommand}>
                      <div
                        onClick={editCommandHandler(
                          rk,
                          value,
                          k as SectionKey,
                          v.commands,
                          data,
                        )}
                        className={styles.commandIcon}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="#717e90"
                        >
                          <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z" />
                        </svg>
                      </div>
                    </div>
                  ),
                },
                {
                  id: 'ignore',
                  hide: () => {
                    return (
                      status === 'PASS' || (k as SectionKey) === 'Diagnostics'
                    );
                  },
                  disabled: ({ command }) => !!v.commands[command]?.isExecuting,
                  element: (data) => (
                    <div className={styles.headerActionCommand}>
                      <Icon
                        onClick={ignoreCommandHandler(
                          rk,
                          value,
                          k as SectionKey,
                          v.commands,
                          data,
                        )}
                        className={styles.commandIcon}
                        size={'1.5rem'}
                        name={
                          v.commands[data.command]?.isIgnored
                            ? 'revert'
                            : 'ignore'
                        }
                      />
                    </div>
                  ),
                },
                {
                  id: 'copy',
                  element: (data) => (
                    <div className={styles.headerActionCommand}>
                      <Icon
                        onClick={copyToClipboard(
                          data?.command,
                          () => {
                            showToast({
                              severity: 'success',
                              detail: 'Text copied to clipboard!',
                              life: 3000,
                            });
                          },
                          (err) => {
                            showToast({
                              severity: 'error',
                              summary: 'Failed to copy text to clipboard:',
                              detail: err,
                              life: 3000,
                            });
                          },
                        )}
                        className={styles.commandIcon}
                        size={'1.5rem'}
                        name="copy"
                      />
                    </div>
                  ),
                },
                {
                  id: 'execute',
                  hide: () => {
                    return (
                      status === 'PASS' || (k as SectionKey) === 'Diagnostics'
                    );
                  },
                  disabled: ({ command }) => !!v.commands[command]?.isExecuting,
                  element: (data) => (
                    <div className={styles.headerActionCommand}>
                      <Icon
                        onClick={executeCommandHandler(
                          rk,
                          value,
                          k as SectionKey,
                          v.commands,
                          data,
                        )}
                        className={cx(styles.commandIcon, {
                          [styles.isExecuting]:
                            v.commands[data.command]?.isExecuting,
                        })}
                        size={'1.5rem'}
                        name="execute"
                      />
                    </div>
                  ),
                },
              ]}
              items={v.commands || {}}
            />
          ) : (
            v.value
          ),
        //`This status indicates that the Pod of Deployment is not able to be scheduled or started. The Pods might be stuck in Pending status due to no Nodes being available, due to specific Pod requirements or constraints, no available resources (CPU or Memory), pod scheduling limitations, or other. Just the status of Deployment does not provide enough details to conclude the root cause, so it’s important to extract and analyze additional details about the Pods, Events and other related objects.`,
      } as AccordionItem<ExecutedCommand>;
    });

    iterrationItems.push({
      id: toUnderscore(`${rk}`),
      data: value,
      key: rk,
      isExpanded: value.isExpanded,
      header: rk,
      ...propsBy[rk],
      content:
        (childItems || [])?.length > 0 ? (
          <Accordion
            expanded={
              (activeIndexes || {})[rk] || childItems?.map((_, k) => k || 0)
            }
            useExpandedState
            onTabChange={onTabSectionChangeHandler(rk)}
            items={childItems || []}
          />
        ) : (
          <LoadingAccordingList message="We are creating new recommendations" />
        ),
    } as AccordionItem<Iteration>);
  });
  return (
    <Accordion
      classNameContent={cx(styles.accordionContent)}
      classNameTab={cx(styles.accordionTab)}
      expanded={
        (activeIndexes || {})['root'] || iterrationItems?.map((_, k) => k || 0)
      }
      useExpandedState
      onTabChange={onTabRootChangeHandler}
      items={iterrationItems || []}
    />
  );
};
