import { ExplainWithAIBanner } from 'components/banner';
import React, { ReactNode, useState } from 'react';
import {
  CommandType,
  RecommendationsService,
  UpdateRecommendationRequest,
} from 'shared/api/client';
import { useDialog } from 'shared/providers/dialog/context';
import { useToast } from 'shared/providers/toast/context';
import { SectionKey, useCheckDetails } from '.';
import {
  EditCommandContext,
  EditCommandContextType,
  Options,
} from './edit-command.context';

interface EditCommandProviderProps {
  children: ReactNode;
}

const commandTypeBy: { [key in SectionKey]?: CommandType } = {
  'Possible diagnostics': 'diagnostic',
  'Possible fixes': 'fix',
};

export const EditCommandProvider: React.FC<EditCommandProviderProps> = ({
  children,
}) => {
  const [inputValue, setInputValue] = useState<
    EditCommandContextType['inputValue']
  >({});

  const { updateCommand } = useCheckDetails();

  const [loading, setLoading] = useState(false);
  const { showApiError } = useToast();
  const { openDialog } = useDialog();

  const [inputType, setInputType] = useState({
    name: 'Custom command',
    code: 'custom',
  });

  const onSent = ({
    iterationId,
    iterationKey,
    sectionId,
    command,
    commandId,
  }: Options) => {
    if (iterationId) {
      const currentValue = (((inputValue || {})[iterationKey] || {})[
        sectionId
      ] || {})[commandId || command || ''];
      setLoading(true);
      RecommendationsService.createCommandFromTextApiV1RecommendationsRecommendationIdTextToCommandPost(
        {
          recommendationId: iterationId,
          requestBody: {
            text: currentValue,
          },
        },
      )
        .then((res) => {
          setInputValue((prev) => ({
            ...prev,
            [iterationKey]: {
              ...(prev[iterationKey] || {}),
              [sectionId]: {
                ...((prev[iterationKey] || {})[sectionId] || {}),
                [commandId || command || '']: res.command,
              },
            },
          }));
          setInputType({ name: 'Custom command', code: 'custom' });
          setLoading(false);
        })
        .catch((e) => {
          if (e?.status === 403) {
            openDialog({ content: <ExplainWithAIBanner /> });
          } else {
            showApiError(e);
          }
          setLoading(false);
        });
    }
  };

  const onConfirm = ({
    iterationId,
    sectionId,
    iterationKey,
    commandId,
    command,
    mode,
  }: Options) => {
    if (iterationId) {
      const currentValue = (((inputValue || {})[iterationKey] || {})[
        sectionId
      ] || {})[commandId || command || ''];
      const requestBody: UpdateRecommendationRequest = {
        command: currentValue,
        command_type: commandTypeBy[sectionId] as CommandType,
      };

      if (mode === 'edit') {
        requestBody.replace_command = command;
      }

      RecommendationsService.updateRecommendationCommandApiV1RecommendationsRecommendationIdCommandPatch(
        {
          recommendationId: iterationId,
          requestBody,
        },
      )
        .then((res) => {
          updateCommand(
            iterationKey,
            sectionId,
            command,
            { isEditing: false, isNew: false, isExpanded: false, output: null },
            currentValue,
          );
          setInputType({ name: 'Custom command', code: 'custom' });
        })
        .catch((e) => {
          showApiError(e);
        });
    }
  };

  const value: EditCommandContextType = {
    inputValue,
    inputType,
    loading,
    setLoading,
    setInputValue,
    setInputType,
    onConfirm,
    onSent,
  };

  return (
    <EditCommandContext.Provider value={value}>
      {children}
    </EditCommandContext.Provider>
  );
};
