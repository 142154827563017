import { AccordionItem } from 'components';
import { Iteration } from './context';

export const titleIteration = 'Recommendation';

export const propsBy: { [key: string]: Partial<AccordionItem<Iteration>> } = {
  Summary: {
    severity: 'summary',
    iconProps: {
      color: '#2D9CDB',
      name: 'details',
    },
  },
  Diagnostics: {
    iconProps: {
      color: '#2D9CDB',
      name: 'possible-diagnostics',
    },
  },
  'Possible diagnostics': {
    iconProps: {
      color: '#2D9CDB',
      name: 'possible-diagnostics',
    },
  },
  'Possible fixes': {
    iconProps: {
      color: '#2D9CDB',
      name: 'possible-fixes',
    },
  },
};
