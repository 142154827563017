import { Button } from 'primereact/button';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ChecksService } from 'shared/api/client';
import { useDialog } from 'shared/providers/dialog/context';
import { useToast } from 'shared/providers/toast/context';
import { CheckDetailsState } from '../failed-check-details/context';
import { fetchCheckResultDetails } from '../shared/requests';
import { filterCommandsByCondition, mapRecommendations } from '../shared/utils';
import styles from './styles.module.scss';

export const ResetRecommendationsModal = () => {
  return (
    <div className={styles.reqRecommendationsModal}>
      <p className={styles.content}>
        Are you sure you want reset recommendations?
      </p>
    </div>
  );
};

interface ResetRecommendationsModalFooterProps {
  executionId?: string;
  checkResultId?: string;
  setData: (state: CheckDetailsState) => void;
  navigate: NavigateFunction;
  setResetLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResetRecommendationsModalFooter = ({
  executionId,
  checkResultId,
  setResetLoading,
  setData,
}: ResetRecommendationsModalFooterProps) => {
  const { closeDialog } = useDialog();
  const { showApiError } = useToast();

  const onResetHandler = () => {
    closeDialog();

    if (executionId && checkResultId) {
      setResetLoading(true);
      ChecksService.resetRecommendationsApiV1ChecksCheckResultIdResetRecommendationsDelete(
        {
          checkResultId,
        },
      )
        .then((res) => {
          fetchCheckResultDetails({ executionId, checkResultId })
            .then((result) => {
              setData({
                details: result,
                iterations: {
                  'System diagnostics': {
                    id: 'system_diagnostics',
                    isExpanded: true,
                    data: {
                      Summary: {
                        type: 'summary',
                        isExpanded: true,
                        value: result?.explanation,
                      },
                      Diagnostics: {
                        type: 'commands',
                        isExpanded: true,
                        commands:
                          filterCommandsByCondition(
                            result?.executedCommands,
                            (x) => !x.recommendationId,
                          ) || {},
                      },
                    },
                  },
                  ...mapRecommendations(
                    result?.recommendations || [],
                    result?.executedCommands || {},
                  ),
                },
              });
              setResetLoading(false);
            })
            .catch((e) => {
              setResetLoading(false);
              showApiError(e);
            });
        })
        .catch((e) => {
          setResetLoading(false);
          showApiError(e);
        });
    }
  };

  return (
    <div className={styles.reqRecommendationsModalFooter}>
      <Button onClick={onResetHandler}>Reset recommendations</Button>
      <Button onClick={closeDialog} severity="secondary">
        Cancel
      </Button>
    </div>
  );
};
