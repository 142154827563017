import React, { CSSProperties } from 'react';
import cx from 'classnames';

export interface IconProps {
  name: string;
  size?: CSSProperties['fontSize'];
  color?: CSSProperties['color'];
  style?: CSSProperties;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const Icon = ({
  onClick,
  className,
  style,
  color,
  size,
  name,
}: IconProps) => {
  if (!name) return <></>;
  return (
    <span
      data-testid="icon"
      onClick={onClick}
      style={{ fontSize: size, color, ...style }}
      className={cx('unskript-icon', `unskriptIcon-${name}`, className)}
    ></span>
  );
};
