import React from 'react';
import cx from 'classnames';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LoadingDots } from 'components';
import styles from './styles.module.scss';

interface LoadingAccordingListProps {
  message: string;
}

export const LoadingAccordingList = ({
  message,
}: LoadingAccordingListProps) => {
  return (
    <div className={cx(styles.loading)}>
      <ProgressSpinner
        style={{
          width: '4rem',
          height: '4rem',
          margin: 0,
          marginBottom: '1rem',
        }}
      />
      {message}
      <span>
        Please wait <LoadingDots />
      </span>
    </div>
  );
};
