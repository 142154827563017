import { ExecutedCommand } from 'pages/reports/shared/models';
import React, { ReactNode, useState } from 'react';
import {
  CheckDetailsContext,
  CheckDetailsContextType,
  CheckDetailsState,
  Iteration,
  SectionKey,
} from './index';

interface CheckDetailsProviderProps {
  children: ReactNode;
}

export const CheckDetailsProvider: React.FC<CheckDetailsProviderProps> = ({
  children,
}) => {
  const [checkDetailsState, setCheckDetailsState] =
    useState<CheckDetailsState | null>(null);

  const setData = (state: CheckDetailsState) => {
    setCheckDetailsState((prev) => ({ ...prev, ...state }));
  };

  const addIteration = (key: string, data: Iteration) => {
    setCheckDetailsState((prev) => ({
      ...prev,
      iterations: { ...prev?.iterations, [key]: data },
    }));
  };

  const removeIteration = (key: string) => {
    setCheckDetailsState((prev) => {
      const cloneIterations = { ...(prev?.iterations || {}) };
      delete cloneIterations[key];
      return {
        ...prev,
        iterations: cloneIterations,
      };
    });
  };

  const addIterations = (data: Record<SectionKey, Iteration>) => {
    setCheckDetailsState((prev) => ({
      ...prev,
      iterations: { ...prev?.iterations, ...data },
    }));
  };

  const updateIteration = (key: string, data: Iteration) => {
    setCheckDetailsState((prev) => ({
      ...prev,
      iterations: { ...prev?.iterations, [key]: data },
    }));
  };

  const addCommand = (
    iterationID: string,
    sectionId: SectionKey,
    data: ExecutedCommand,
  ) => {
    setCheckDetailsState((prev) => {
      const newIterations = { ...(prev?.iterations || {}) };
      const newIteration = { ...(newIterations[iterationID] || {}) };
      const sections = { ...(newIteration?.data || {}) };
      const section = sections[sectionId];

      if (section?.type === 'commands') {
        section.commands = {
          ...(section.commands || {}),
          // eslint-disable-next-line no-useless-computed-key
          ['']: {
            recommendationId: iterationID,
            output: null,
            isNew: true,
            isEditing: true,
            isExecuting: false,
            isIgnored: false,
            order: Object.keys(sections || {})?.length,
            ...data,
          },
        };
      }

      return {
        ...prev,
        iterations: {
          ...newIterations,
          [iterationID]: {
            ...newIteration,
            data: {
              ...sections,
              [sectionId]: {
                ...sections[sectionId],
                [sectionId]: section,
              },
            },
          },
        },
      };
    });
  };
  const removeCommand = (
    iterationID: string,
    sectionId: SectionKey,
    commandKey: string,
  ) => {
    setCheckDetailsState((prev) => {
      const newIterations = { ...(prev?.iterations || {}) };
      const newIteration = { ...(newIterations[iterationID] || {}) };
      const sections = { ...(newIteration?.data || {}) };
      const newSection = sections[sectionId];

      if (newSection && newSection.type === 'commands') {
        delete newSection.commands[commandKey];
      }

      const newState: CheckDetailsState = {
        ...prev,
        iterations: {
          ...newIterations,
          [iterationID]: {
            ...newIteration,
            data: {
              ...sections,
              [sectionId]: {
                ...newSection,
              },
            },
          },
        },
      };

      return newState;
    });
  };

  const updateCommand = (
    iterationID: string,
    sectionId: SectionKey,
    commandKey: string,
    data: ExecutedCommand,
    newCommandKey?: string,
  ) => {
    setCheckDetailsState((prev) => {
      const newIterations = { ...(prev?.iterations || {}) };
      const newIteration = { ...(newIterations[iterationID] || {}) };
      const sections = { ...(newIteration?.data || {}) };
      const newSection = sections[sectionId];

      if (newCommandKey && newSection?.type === 'commands') {
        const newCommand = { ...newSection.commands[commandKey], ...data };
        delete newSection.commands[commandKey];
        newSection.commands[newCommandKey] = newCommand;
      } else {
        if (newSection?.type === 'commands') {
          newSection.commands[commandKey] = {
            ...newSection.commands[commandKey],
            ...data,
          };
        }
      }

      const newState = {
        ...prev,
        iterations: {
          ...newIterations,
          [iterationID]: {
            ...newIteration,
            data: {
              ...sections,
              [sectionId]: {
                ...newSection,
              },
            },
          },
        },
      };

      return newState;
    });
  };

  const value: CheckDetailsContextType = {
    setData,
    updateData: setCheckDetailsState,
    addIteration,
    addIterations,
    addCommand,
    updateCommand,
    removeCommand,
    updateIteration,
    removeIteration,
    iterations: checkDetailsState?.iterations || {},
    details: checkDetailsState?.details || {},
  };

  return (
    <CheckDetailsContext.Provider value={value}>
      {children}
    </CheckDetailsContext.Provider>
  );
};
