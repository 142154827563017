import { ExecutedCommand } from 'pages/reports/shared/models';
import { createContext, useContext } from 'react';
import { CheckStatus } from 'shared/api/client';

export interface Details {
  id?: string;
  objectName?: string;
  checkTitle?: string;
  severity?: string;
  summary?: string;
  namespace?: string;
  status?: CheckStatus;
  explanation?: string;
}

export type SectionKey =
  | 'Summary'
  | 'Diagnostics'
  | 'Possible diagnostics'
  | 'Possible fixes';

export interface CommandSection {
  type: 'commands';
  commands: Record<string, ExecutedCommand | null>;
}
export interface SummarySection {
  type: 'summary';
  value: string;
}
export type Section = (CommandSection | SummarySection) & {
  isExpanded: boolean;
};

export type Iteration = {
  id?: string | null;
  isExpanded: boolean;
  data?: {
    [key in SectionKey]?: Section;
  };
};

export interface CheckDetailsState {
  details?: Details;
  iterations?: Record<string, Iteration>;
}

export interface CheckDetailsContextType {
  details: Details;
  iterations: Record<string, Iteration>;
  setData: (state: CheckDetailsState) => void;
  updateData: (value: React.SetStateAction<CheckDetailsState | null>) => void;
  addIteration: (key: SectionKey, data: Iteration) => void;
  removeCommand: (
    iterationID: string,
    sectionId: SectionKey,
    commandKey: string,
  ) => void;
  updateCommand: (
    iterationID: string,
    sectionId: SectionKey,
    commandKey: string,
    data: ExecutedCommand,
    newCommandKey?: string,
  ) => void;
  updateIteration: (key: string, data: Iteration) => void;
  addCommand: (
    iterationID: string,
    sectionId: SectionKey,
    data: ExecutedCommand,
  ) => void;
  removeIteration: (key: string) => void;
  addIterations: (data: Record<string, Iteration>) => void;
}

export const CheckDetailsContext = createContext<CheckDetailsContextType>({
  iterations: {},
  details: {},
} as CheckDetailsContextType);

export const useCheckDetails = () => {
  const context = useContext(CheckDetailsContext);
  if (!context) {
    throw new Error(
      'useCheckDetails must be used within a CheckDetailsProvider',
    );
  }
  return context;
};
