import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components/icon';
import { Skeleton } from 'primereact/skeleton';

export interface TileProps {
  title: React.ReactNode;
  showSkeleton?: boolean;
  textColor?: 'green' | 'red' | 'grey';
  count: number;
  icon: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Tile = ({
  title,
  showSkeleton,
  textColor,
  icon,
  count,
  onClick,
}: React.PropsWithChildren<TileProps>) => {
  return (
    <div onClick={onClick} className={cx(styles.tileLayout, styles.tileStyle)}>
      <div className={cx(styles.context)}>
        <h4 className={cx(styles.titleLayout, styles.titleStyle)}>
          {showSkeleton ? <Skeleton width="6rem" height="1rem" /> : title}
        </h4>
        <div
          className={cx(
            styles.count,
            styles.countStyle,
            styles[textColor as string],
          )}
        >
          {showSkeleton ? <Skeleton /> : count}
        </div>
      </div>
      <div className={cx(styles.iconBoxLayout, styles.iconBoxStyle)}>
        <Icon
          className={cx(styles.icon, styles[textColor as string])}
          name={icon}
          size={'2.5rem'}
        />
      </div>
    </div>
  );
};
