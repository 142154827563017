import React, { useEffect, useState } from 'react';
import { ReportTableDataItem } from 'pages/reports/shared/models';
import { Icon } from 'components/icon';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { isEmptyString } from 'shared/utils';
import { ChecksService } from 'shared/api/client';
import { Carousel } from 'components/carousel';
import { useToast } from 'shared/providers/toast/context';
import { useDialog } from 'shared/providers/dialog/context';
import { ExplainWithAIBanner } from 'components/banner';

interface ExplanationColumnBodyProps {
  row: ReportTableDataItem;
}

interface AIExplain {
  explanation?: string;
  aiExplanation?: string | null;
}

interface Explanation {
  id: string;
  content?: string | null;
  name: string;
  order: number;
  expanded?: boolean;
  animation?: boolean;
}

export const ExplanationColumnCell = ({ row }: ExplanationColumnBodyProps) => {
  const [loading, setLoading] = useState(false);
  const [explanations, setExplanations] = useState<Explanation[]>([]);
  const [explain, setExplain] = useState<AIExplain | null>(null);
  const { showApiError } = useToast();

  const { openDialog } = useDialog();

  useEffect(() => {
    setExplain({
      explanation: row?.explanation,
      aiExplanation: row?.explainWithAI,
    });
    const explanationsList: Explanation[] = [
      {
        id: 'explanation',
        content: row?.explanation,
        name: 'System Explanation',
        animation: false,
        order: 1,
      },
    ];
    if (!isEmptyString(row?.explainWithAI)) {
      explanationsList.push({
        id: 'ai',
        content: row?.explainWithAI,
        name: 'AI Explanation',
        animation: false,
        order: 0,
      });
    }
    setExplanations(explanationsList);
  }, [row]);

  const onClickExplainWithAIHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (row?.executionId && row?.checkResultId) {
      setLoading(true);
      ChecksService.aiExplainApiV1ChecksCheckResultIdExplainPost({
        checkResultId: row?.checkResultId,
      })
        .then((res) => {
          setExplain({
            explanation: res.explanation,
            aiExplanation: res.ai_explanation,
          });
          setExplanations((prev) => {
            const newState = [...prev];
            newState.push({
              id: 'ai',
              name: 'AI Explanation',
              content: res?.ai_explanation,
              order: 0,
              animation: true,
              expanded: true,
            });
            return newState;
          });
          setLoading(false);
        })
        .catch((e) => {
          if (e?.status === 403) {
            openDialog({ content: <ExplainWithAIBanner /> });
          } else {
            showApiError(e);
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className={cx(styles.explanationCell)}>
      <Carousel
        items={(explanations || [])?.sort((a, b) => a.order - b.order)}
      />
      {loading ? (
        <div className={cx(styles.button, styles.loading)}>
          <ProgressSpinner
            style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
          />
          AI explanation in progress. Please wait...
        </div>
      ) : isEmptyString(explain?.aiExplanation) && row?.status !== 'PASS' ? (
        <div
          onClick={onClickExplainWithAIHandler}
          className={cx(styles.button)}
        >
          <Icon className={cx(styles.icon)} size={'1.5rem'} name="AI" />
          Explain with AI
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
