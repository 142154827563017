import React from 'react';
import styles from './styles.module.scss';
import { ResultStatus } from 'components';
import { toCapitalize } from 'shared/utils';

import { useCheckDetails } from './context';

export const CheckDetailsTabView = () => {
  const { details } = useCheckDetails();

  return (
    <div className={styles.detailsTab}>
      <div>
        <label>Status:</label>
        <p>
          <ResultStatus type={details?.status}>
            {toCapitalize((details?.status || '')?.toLowerCase())}
          </ResultStatus>
        </p>
      </div>
      <div>
        <label>Check:</label>
        <p>{details?.checkTitle}</p>
      </div>
      <div>
        <label>Namespace:</label>
        <p>{details?.namespace}</p>
      </div>
      <div>
        <label>Object:</label>
        <p>{details?.objectName}</p>
      </div>
      <div>
        <label>Severity:</label>
        <p>{details?.severity}</p>
      </div>
      <div>
        <label>Summary:</label>
        <p>{details?.explanation}</p>
      </div>
    </div>
  );
};
