/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TextToCommandRequest } from '../models/TextToCommandRequest';
import type { TextToCommandResponse } from '../models/TextToCommandResponse';
import type { UpdateRecommendationRequest } from '../models/UpdateRecommendationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecommendationsService {
  /**
   * Create Command From Text
   * @returns TextToCommandResponse Successful Response
   * @throws ApiError
   */
  public static createCommandFromTextApiV1RecommendationsRecommendationIdTextToCommandPost({
    recommendationId,
    requestBody,
  }: {
    recommendationId: string;
    requestBody: TextToCommandRequest;
  }): CancelablePromise<TextToCommandResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/recommendations/{recommendation_id}/text-to-command',
      path: {
        recommendation_id: recommendationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Update Recommendation Command
   * @returns void
   * @throws ApiError
   */
  public static updateRecommendationCommandApiV1RecommendationsRecommendationIdCommandPatch({
    recommendationId,
    requestBody,
  }: {
    recommendationId: string;
    requestBody: UpdateRecommendationRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/recommendations/{recommendation_id}/command',
      path: {
        recommendation_id: recommendationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
}
