import React from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePageData } from 'router/shared/models';

const ReportsOverview = () => {
  return <Navigate to="/reports/last" replace />;
};

export default {
  title: 'Reports',
  route: { Component: ReportsOverview, path: '/reports' },
  wrapToPage: false,
  pageStyle: 'summary',
} as RoutePageData;
