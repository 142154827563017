import React, { useEffect, useState } from 'react';
import { BaseScrollOptions, useHashScroll } from 'hooks/use-hash-scroll';
import {
  Accordion as PrimeAccordion,
  AccordionTab as PrimeAccordionTab,
  AccordionTabChangeEvent,
} from 'primereact/accordion';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components/icon';
import { useNavigate } from 'react-router';
import { AccordionItem } from './models';

export * from './models';

interface AccordionProps<T> {
  items: AccordionItem<T>[];
  style?: React.CSSProperties;
  classNameContent?: string;
  classNameHeader?: string;
  classNameTab?: string;
  expandShow?: boolean;
  useExpandedState?: boolean;
  expanded?: number[] | number | null;
  onTabChange?: (
    event: AccordionTabChangeEvent & {
      tab: AccordionItem<T>;
      selected: boolean;
    },
  ) => void;
}

function Header<T>(props: AccordionItem<T>) {
  const { header, actions, severity, iconProps, id, headerClassName } = props;
  const options: Partial<BaseScrollOptions> = {
    position: 'start',
    behavior: 'smooth',
    scrollFunc: (ref, behavior, position) => {
      ref.current?.closest('.p-accordion-tab')?.scrollIntoView({
        behavior,
        block: position,
        inline: position,
      });
    },
  };

  const ref = useHashScroll<HTMLDivElement>(`${id}`, options);
  return (
    <div
      onDoubleClick={props.onDoubleClick}
      ref={ref}
      className={cx(styles.header, styles[severity as string])}
    >
      {iconProps && <Icon {...iconProps} size={'1.5rem'} />}

      <div className={cx(styles.headerTab)}>
        <p className={cx(headerClassName)}>{header}</p>
        <div className={styles.headerAction}>
          {(actions || [])
            .filter((x) => {
              return typeof x.hide === 'function' ? !x?.hide(props) : !x?.hide;
            })
            .map((c, cindex) => {
              return (
                <div
                  className={cx(styles.actionWrapper, {
                    [styles.disabled]:
                      typeof c.disabled === 'function'
                        ? c.disabled(props)
                        : c.disabled,
                  })}
                  key={`accordion${id}_${c.id}_comand_${cindex}}`}
                >
                  {typeof c?.element === 'function'
                    ? c?.element(props)
                    : c?.element}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export function Accordion<T>({
  items,
  style,
  classNameTab,
  classNameContent,
  classNameHeader,
  expanded,
  useExpandedState,
  expandShow = true,
  onTabChange,
}: AccordionProps<T>) {
  const navigate = useNavigate();

  const [activeIndexes, setActiveIndexes] = useState<number[] | number | null>(
    expanded || [],
  );

  useEffect(() => {
    if (expanded && !useExpandedState) {
      setActiveIndexes(expanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    if (useExpandedState) {
      setActiveIndexes((prev) => {
        if (Array.isArray(prev)) {
          let newState = [...prev];
          (items || [])?.forEach((x, i) => {
            if (Object.hasOwn(x, 'isExpanded') && x?.isExpanded) {
              newState.push(i);
            } else {
              if (!x?.isExpanded && prev?.includes(i)) {
                newState = newState?.filter((el) => el !== i);
              }
              if (!Object.hasOwn(x, 'isExpanded')) {
                newState = newState?.filter((el) => el !== i);
              }
            }
          });

          const arr = new Set([...newState]);
          return Array.from(arr);
        }
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const isSelected = (index: number) => {
    return Array.isArray(activeIndexes)
      ? activeIndexes && activeIndexes.some((i) => i === index)
      : activeIndexes === index;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onChangeHandler = () => {};

  const onTabChangeHandler =
    (index: number, tab: AccordionItem<T>) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (tab.blocked) {
        return;
      }

      const selected = isSelected(index);

      const indexes = Array.isArray(activeIndexes)
        ? activeIndexes || []
        : activeIndexes
          ? [activeIndexes]
          : [];

      const newActiveIndex = selected
        ? (Array.isArray(activeIndexes)
            ? activeIndexes
            : activeIndexes
              ? [activeIndexes]
              : []
          )?.filter((i) => i !== index)
        : [...indexes, index];
      setActiveIndexes(newActiveIndex);
      if (typeof onTabChange === 'function') {
        onTabChange({
          originalEvent: e,
          index: newActiveIndex,
          tab,
          selected: !selected,
        });
      }
    };

  return (
    <div className={cx(styles.accordionWrapper)}>
      <PrimeAccordion
        style={style}
        onTabChange={onChangeHandler}
        pt={{
          accordiontab: {
            onKeyDown: null,
          },
          root: {
            onKeyDown: null,
          },
        }}
        expandIcon={
          expandShow ? (
            <Icon
              className="expandIcon"
              style={{ paddingRight: '0.5rem' }}
              size={'1.5rem'}
              name="expand_more"
            />
          ) : (
            <></>
          )
        }
        collapseIcon={
          expandShow ? (
            <Icon
              className="collapseIcon"
              style={{ paddingRight: '0.5rem' }}
              size={'1.5rem'}
              name="expand_less"
            />
          ) : (
            <></>
          )
        }
        className={cx(styles.accordion)}
        multiple
        activeIndex={activeIndexes}
      >
        {(items || []).map((x, i) => {
          return (
            <PrimeAccordionTab
              pt={{
                headerAction: {
                  onKeyDown: null,
                  onClick: onTabChangeHandler(i, x),
                  href: null,
                },
                root: {
                  onKeyDown: null,
                  onClick: (e) => {
                    navigate({ hash: `#` });
                  },
                  className: cx(
                    styles.accordionTab,
                    styles[`accordionTabRoot_${x?.severity as string}`],
                    `accordionTab-${x?.severity || 'default'}`,
                    classNameTab,
                  ),
                },
              }}
              key={`accordionTab-${x.command}_${i}`}
              className={cx(
                styles.tab,
                {
                  [styles.summary]: x.severity === 'summary',
                  [styles.command]: x.severity === 'command',
                },
                classNameContent,
              )}
              headerClassName={cx({
                [styles.emptyTabHeader]: !x?.content,
                [styles.hideCollapse]: x?.hideCollapse,
              })}
              header={
                <Header
                  onDoubleClick={x?.onDoubleClick}
                  headerClassName={classNameHeader}
                  {...x}
                />
              }
            >
              {x.content && (
                <div
                  style={x.contentStyles}
                  className={cx(
                    styles.tabContent,
                    styles[x.severity as string],
                    x.contentClassName,
                  )}
                >
                  {x.content}
                </div>
              )}
            </PrimeAccordionTab>
          );
        })}
      </PrimeAccordion>
    </div>
  );
}
