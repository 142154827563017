import { Button } from 'primereact/button';
import React from 'react';
import { useDialog } from 'shared/providers/dialog/context';
import { getAssetsPath } from 'shared/utils';
import styles from './styles.module.scss';

export const ExplainWithAIBanner = () => {
  const { closeDialog } = useDialog();

  const upgradeSubscriptionHandler = () => {
    window.open('https://unskript.com/', '_blank');
    closeDialog();
  };

  return (
    <div className={styles.banner}>
      <img
        className={styles.bannerLogo}
        src={getAssetsPath('explain-banner')}
        alt="explain-banner"
      />
      <div className={styles.content}>
        <div className={styles.body}>
          <h3 className={styles.title}>
            {`"Explain with AI" not available for the free version`}
          </h3>
          <p className={styles.text}>
            Please upgrade your subscription to use this feature.
          </p>
        </div>
        <Button
          onClick={upgradeSubscriptionHandler}
          className={styles.btnUpgradeSubscription}
        >
          Upgrade Subscription
        </Button>
      </div>
    </div>
  );
};
