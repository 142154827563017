import React, { useEffect } from 'react';
import {
  Accordion,
  Icon,
  AccordionItem,
  ActionAccordionItem,
  LoadingDots,
} from 'components';
import { JsonViewer } from 'components/json-viewer';
import styles from './styles.module.scss';
import cx from 'classnames';
import {
  copyToClipboard,
  djb2Hash,
  isNullOrUndefined,
  toUnderscore,
} from 'shared/utils';
import { useToast } from 'shared/providers/toast/context';
import { ExecutedCommand } from '../shared/models';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AccordionTabChangeEvent } from 'primereact/accordion';
import { AddCommandButton } from './add-command-button';
import { CommandSection, SectionKey, useCheckDetails } from './context';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useEditCommand } from './context/edit-command.context';
import { EditCommandProvider } from './context/edit-command.provider';

export const mapCommands = (
  rootKey: string,
  iterationKey: string,
  iterationId: string | null | undefined,
  sectionId: SectionKey,
  data: Record<string, ExecutedCommand | null>,
  updateCommand: (
    iterationID: string,
    sectionId: SectionKey,
    commandKey: string,
    data: ExecutedCommand,
  ) => void,
  actions?: ActionAccordionItem<ExecutedCommand>[],
) => {
  const output: AccordionItem<ExecutedCommand>[] = [];
  for (const command in data) {
    const isEdit = !!data[command]?.isEditing || !!data[command]?.isNew;
    const keyCommand = data[command]?.id || djb2Hash(command);
    output.push({
      id: toUnderscore(`${rootKey}-${keyCommand}`) || null,
      command,
      key: command,
      blocked: isEdit,
      isExpanded: isEdit
        ? true
        : Object.hasOwn(data[command] || {}, 'isExpanded')
          ? data[command]?.isExpanded
          : !!data[command]?.output,
      order: data[command]?.order,
      onDoubleClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        updateCommand(iterationKey, sectionId, command, {
          isEditing: true,
        });
      },
      hideCollapse: isEdit,
      severity: 'command',
      actions: isEdit ? [] : actions,
      header: (
        <CommandHeader
          iterationId={iterationId}
          iterationKey={iterationKey}
          sectionId={sectionId}
          commandId={data[command]?.id}
          isEditing={data[command]?.isEditing}
          isNew={data[command]?.isNew}
          isExecuting={data[command]?.isExecuting}
          mode={data[command]?.isNew ? 'add' : 'edit'}
          isIgnored={data[command]?.isIgnored}
          command={command}
        />
      ),
      content:
        data[command]?.isEditing || data[command]?.isNew ? (
          <EditingFooter
            iterationId={iterationId}
            commandId={data[command]?.id}
            mode={data[command]?.isNew ? 'add' : 'edit'}
            iterationKey={iterationKey}
            sectionId={sectionId}
            command={command}
            data={data[command]}
          />
        ) : typeof data[command]?.output === 'string' ? (
          <Output data={data[command]} />
        ) : null,
      contentStyles: { padding: 0, margin: 0 },
    });
  }
  return (output || []).sort((a, b) => (a?.order || 0) - (b?.order || 0));
};

interface EditingFooterProps {
  mode: 'edit' | 'add';
  iterationKey: string;
  iterationId: string | null | undefined;
  sectionId: SectionKey;
  command: string;
  commandId: string | null | undefined;
  data: ExecutedCommand | null;
}
const EditingFooter = ({
  mode,
  command,
  commandId,
  iterationKey,
  iterationId,
  sectionId,
}: EditingFooterProps) => {
  const { removeCommand, updateCommand, iterations } = useCheckDetails();

  const { inputType, setInputType, loading, onSent, onConfirm } =
    useEditCommand();

  const onCancel = () => {
    setInputType({ name: 'Custom command', code: 'custom' });
    if (mode === 'add') {
      removeCommand(iterationKey, sectionId, command);
    } else {
      const prev = ((
        ((((iterations || {})[iterationKey] || {}).data || {})[sectionId] ||
          {}) as CommandSection
      ).commands || {})[command];

      const isExpanded = Object.hasOwn(prev || {}, 'isExpanded')
        ? prev?.isExpanded
        : !!prev?.output;

      updateCommand(iterationKey, sectionId, command, {
        isEditing: false,
        isExpanded: isExpanded || false,
      });
    }
  };

  const onSentHandler = () => {
    onSent({
      iterationId,
      iterationKey,
      sectionId,
      command,
      commandId,
      mode,
    });
  };

  const onConfirmHandler = () => {
    onConfirm({
      iterationId,
      iterationKey,
      sectionId,
      command,
      commandId,
      mode,
    });
  };

  return (
    <div className={styles.editingFooter}>
      <div onClick={onCancel} className={styles.cancel}>
        Cancel
      </div>
      {inputType.code === 'ai' ? (
        <div onClick={onSentHandler} className={styles.confirm}>
          {loading ? (
            <div className={styles.loading}>
              <ProgressSpinner
                style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
              />
            </div>
          ) : (
            <Icon
              className={styles.icon}
              size={'1.5rem'}
              name="send_a_message"
            />
          )}
          {loading ? (
            <>
              Please wait <LoadingDots />
            </>
          ) : (
            `Send request`
          )}
        </div>
      ) : (
        <div onClick={onConfirmHandler} className={styles.confirm}>
          <Icon className={styles.icon} size={'1.5rem'} name="check" />
          Confirm
        </div>
      )}
    </div>
  );
};

const Output = ({ data }: { data: ExecutedCommand | null }) => {
  const { showToast } = useToast();

  return (
    <div className={styles.outputCommand}>
      <h5>Output</h5>

      <div className={styles.contentOutput}>
        {data?.output === '' ? (
          <div className={styles.empty}>{'Empty output'}</div>
        ) : (
          <>
            <Icon
              onClick={copyToClipboard(
                data?.output || '',
                () => {
                  showToast({
                    severity: 'success',
                    detail: 'Text copied to clipboard!',
                    life: 3000,
                  });
                },
                (err) => {
                  showToast({
                    severity: 'error',
                    summary: 'Failed to copy text to clipboard:',
                    detail: err,
                    life: 3000,
                  });
                },
              )}
              className={styles.copy}
              name={'copy'}
              size={'1.5rem'}
            />
            <JsonViewer
              id={data?.id}
              className={styles.jsonViewer}
              jsonData={data?.output || ''}
            />
          </>
        )}
      </div>
    </div>
  );
};

interface EditingProps {
  iterationId: string | null | undefined;
  iterationKey: string;
  sectionId: SectionKey;
  commandId: string | null | undefined;
  command: string;
  mode: 'add' | 'edit';
}

const Editing = ({
  iterationId,
  iterationKey,
  sectionId,
  commandId,
  command,
  mode,
}: EditingProps) => {
  const {
    inputValue,
    inputType,
    setInputType,
    setInputValue,
    onSent,
    onConfirm,
  } = useEditCommand();

  const currentValue = (((inputValue || {})[iterationKey] || {})[sectionId] ||
    {})[commandId || command || ''];

  useEffect(() => {
    setInputValue((prev) => ({
      ...prev,
      [iterationKey]: {
        ...(prev[iterationKey] || {}),
        [sectionId]: {
          ...((prev[iterationKey] || {})[sectionId] || {}),
          [commandId || command || '']: command,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue((prev) => ({
      ...prev,
      [iterationKey]: {
        ...(prev[iterationKey] || {}),
        [sectionId]: {
          ...((prev[iterationKey] || {})[sectionId] || {}),
          [commandId || command || '']: e?.target?.value,
        },
      },
    }));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputType.code === 'ai') {
        onSent({
          iterationId,
          iterationKey,
          sectionId,
          command,
          commandId,
          mode,
        });
      }
      if (inputType?.code === 'custom') {
        onConfirm({
          iterationId,
          iterationKey,
          sectionId,
          command,
          commandId,
          mode,
        });
      }
    }
  };

  const options = [
    { name: 'Custom command', code: 'custom' },
    { name: 'Text to command with AI', code: 'ai' },
  ];
  return (
    <div className={styles.editing}>
      <InputText
        autoFocus
        placeholder="Type your command..."
        onKeyDown={handleKeyDown}
        className={styles.input}
        pt={{
          root: {
            style: {
              padding: '0.15rem 1rem',
            },
          },
        }}
        onChange={onChangeHandler}
        value={currentValue}
      />
      <Dropdown
        style={{ border: 'none', background: 'transparent' }}
        value={inputType}
        pt={{
          input: {
            style: { background: 'transparent', padding: '0.15rem 0.5rem' },
          },
        }}
        onChange={(e) => setInputType(e.value)}
        options={options}
        optionLabel="name"
        placeholder="Select..."
      />
    </div>
  );
};

interface CommandHeaderProps {
  command: string;
  commandId: string | null | undefined;
  iterationId: string | null | undefined;
  iterationKey: string;
  sectionId: SectionKey;
  isExecuting?: boolean;
  isEditing?: boolean;
  isNew?: boolean;
  mode: 'edit' | 'add';
  isIgnored?: boolean;
}

const CommandHeader = ({
  commandId,
  iterationId,
  iterationKey,
  sectionId,
  isExecuting,
  isEditing,
  isNew,
  isIgnored,
  command,
  mode,
}: CommandHeaderProps) => {
  const onClickInput = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (isEditing || isEditing) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <span onClick={onClickInput} className={styles.headerCommand}>
      {isEditing || isNew ? (
        <Editing
          iterationId={iterationId}
          iterationKey={iterationKey}
          sectionId={sectionId}
          commandId={commandId}
          mode={mode}
          command={command}
        />
      ) : (
        <>
          <span className={styles.spinner}>
            {isExecuting && (
              <ProgressSpinner
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  margin: 0,
                }}
              />
            )}
          </span>
          <span
            className={cx(styles.commandText, { [styles.ignored]: isIgnored })}
          >
            {command}
          </span>
        </>
      )}
    </span>
  );
};

interface CommandsProps {
  rootKey: string;
  iterationKey: string;
  iterationId: string | null | undefined;
  sectionKey: SectionKey;
  items: Record<string, ExecutedCommand | null>;
  actions?: ActionAccordionItem<ExecutedCommand>[] | undefined;
}

export const Commands = ({
  iterationKey,
  iterationId,
  sectionKey,
  rootKey,
  items,
  actions,
}: CommandsProps) => {
  const { updateCommand } = useCheckDetails();

  const arrItems = mapCommands(
    rootKey,
    iterationKey,
    iterationId,
    sectionKey,
    items,
    updateCommand,
    actions,
  );

  const onTabChangeHandler = (
    event: AccordionTabChangeEvent & {
      tab: AccordionItem<ExecutedCommand>;
      selected: boolean;
    },
  ) => {
    if (iterationKey) {
      updateCommand(iterationKey, sectionKey, event.tab.command, {
        isExpanded: event.selected,
      });
    }
  };
  return (
    <EditCommandProvider>
      <div className={styles.commandWrapper}>
        {(arrItems || [])?.length > 0 ? (
          <Accordion
            onTabChange={onTabChangeHandler}
            useExpandedState
            expanded={(arrItems || [])
              ?.map((x, i) => (x.content ? i : null) as number)
              .filter((x) => !isNullOrUndefined(x))}
            items={arrItems || []}
          />
        ) : (
          <div className={styles.emptyCommand}>{`No commands provided`}</div>
        )}
        {(sectionKey === 'Possible diagnostics' ||
          sectionKey === 'Possible fixes') && (
          <AddCommandButton
            iterationKey={iterationKey}
            sectionKey={sectionKey}
          />
        )}
      </div>
    </EditCommandProvider>
  );
};
