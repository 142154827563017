/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AIExplainResponse } from '../models/AIExplainResponse';
import type { GetCheckResultResponse } from '../models/GetCheckResultResponse';
import type { ListChecksResponse } from '../models/ListChecksResponse';
import type { ReRunCheckResponse } from '../models/ReRunCheckResponse';
import type { RunCommandsRequest } from '../models/RunCommandsRequest';
import type { RunCommandsResponse } from '../models/RunCommandsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChecksService {
  /**
   * List Checks
   * @returns ListChecksResponse Successful Response
   * @throws ApiError
   */
  public static listChecksApiV1ChecksGet(): CancelablePromise<ListChecksResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/checks',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Ai Explain
   * @returns AIExplainResponse Successful Response
   * @throws ApiError
   */
  public static aiExplainApiV1ChecksCheckResultIdExplainPost({
    checkResultId,
  }: {
    checkResultId: string;
  }): CancelablePromise<AIExplainResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/checks/{check_result_id}/explain',
      path: {
        check_result_id: checkResultId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Check Result
   * @returns GetCheckResultResponse Successful Response
   * @throws ApiError
   */
  public static getCheckResultApiV1ChecksCheckResultIdGet({
    checkResultId,
  }: {
    checkResultId: string;
  }): CancelablePromise<GetCheckResultResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/checks/{check_result_id}',
      path: {
        check_result_id: checkResultId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Run Commands
   * @returns RunCommandsResponse Successful Response
   * @throws ApiError
   */
  public static runCommandsApiV1ChecksCheckResultIdCommandsPost({
    checkResultId,
    requestBody,
  }: {
    checkResultId: string;
    requestBody: RunCommandsRequest;
  }): CancelablePromise<RunCommandsResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/checks/{check_result_id}/commands',
      path: {
        check_result_id: checkResultId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Reset Recommendations
   * @returns void
   * @throws ApiError
   */
  public static resetRecommendationsApiV1ChecksCheckResultIdResetRecommendationsDelete({
    checkResultId,
  }: {
    checkResultId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/checks/{check_result_id}/reset-recommendations',
      path: {
        check_result_id: checkResultId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Rerun Check
   * @returns ReRunCheckResponse Successful Response
   * @throws ApiError
   */
  public static rerunCheckApiV1ChecksCheckResultIdReRunPost({
    checkResultId,
  }: {
    checkResultId: string;
  }): CancelablePromise<ReRunCheckResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/checks/{check_result_id}/re-run',
      path: {
        check_result_id: checkResultId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
}
