import React, { useEffect, useState } from 'react';
import {
  Tree,
  TreeExpandedEvent,
  TreeExpandedKeysType,
  TreeNodeClickEvent,
  TreeTogglerTemplateOptions,
} from 'primereact/tree';
import cx from 'classnames';
import styles from './styles.module.scss';
import { TreeNode } from 'primereact/treenode';
import { Icon, IconProps } from 'components';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SectionKey } from 'pages/reports/failed-check-details/context';

export interface Node extends TreeNode {
  iconProps?: IconProps;
  isLoading?: boolean;
  data?: {
    iterationKey?: string;
    sectionKey?: SectionKey;
    commandId?: string;
    command?: string;
  };
}

interface TreeViewProps {
  nodes: Node[];
  onNodeClick?(event: TreeNodeClickEvent): void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isActive: boolean;
}

export const TreeView = ({
  nodes,
  isActive,
  onNodeClick,
  onClick,
}: TreeViewProps) => {
  const [items, setNodes] = useState<TreeNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({});

  useEffect(() => {
    setNodes(nodes);
    expandAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  const expandNode = (node: TreeNode, _expandedKeys: TreeExpandedKeysType) => {
    if (node.children && node.children.length) {
      expandedKeys[node?.key as string] = true;

      for (const child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const expandAll = () => {
    const expanded = {};

    for (const node of nodes) {
      expandNode(node, expanded);
    }

    setExpandedKeys(expandedKeys);
  };

  const onToggleHandler = (e: TreeExpandedEvent) => {
    setExpandedKeys(e.value);
  };

  const onClickHandler =
    () => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (typeof onClick === 'function') {
        onClick(e);
      }
    };

  const togglerTemplate = (
    node: TreeNode,
    options: TreeTogglerTemplateOptions,
  ) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;

    return (
      <button
        type="button"
        className="p-tree-toggler p-link"
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span className={cx(styles.expandIcon)}>
          <Icon size={'1.25rem'} name={expanded ? 'minus' : 'plus'} />
        </span>
      </button>
    );
  };

  return (
    <div onClick={onClickHandler} className={cx(styles.treeView)}>
      <Tree
        expandedKeys={expandedKeys}
        togglerTemplate={togglerTemplate}
        onToggle={onToggleHandler}
        onNodeClick={onNodeClick}
        nodeTemplate={(p: Node) => (
          <span className={cx(styles.label)}>
            {p?.iconProps && (
              <Icon
                {...p?.iconProps}
                className={cx(p.className, styles.icon)}
                size={'1.5rem'}
                style={{ marginRight: '0.55rem' }}
              />
            )}
            {p?.isLoading && (
              <span className={styles.spinner}>
                <ProgressSpinner
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    margin: 0,
                  }}
                />
              </span>
            )}
            <span className={cx('label')}>{p?.label}</span>
          </span>
        )}
        style={{ backgroundColor: isActive ? '#F5F7FA' : 'transparent' }}
        value={items}
        pt={{
          node: (d) => {
            return {
              className: cx(styles.node, {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                [styles.nodeRoot]: !(d?.context as any).isLeaf,
              }),
            };
          },
        }}
        className="w-full md:w-30rem"
      />
    </div>
  );
};
