import { Button } from 'primereact/button';
import React from 'react';
import { NavigateFunction } from 'react-router';
import { ExecutionsService } from 'shared/api/client';
import { useDialog } from 'shared/providers/dialog/context';
import { useToast } from 'shared/providers/toast/context';
import { PossibleState } from '../models';
import styles from './styles.module.scss';

export const GenerateNewReportModal = () => {
  return (
    <div className={styles.reqGenerateNewReportModal}>
      <p className={styles.content}>
        Are you sure want to generate new report?
      </p>
    </div>
  );
};

interface GenerateNewReportModalFooterProps {
  navigate: NavigateFunction;
  setPageState: React.Dispatch<React.SetStateAction<PossibleState | undefined>>;
  setLoadingGenReport: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GenerateNewReportModalFooter = ({
  navigate,
  setLoadingGenReport,
}: GenerateNewReportModalFooterProps) => {
  const { closeDialog } = useDialog();
  const { showApiError } = useToast();

  const onClickGenerateReportHandler = () => {
    closeDialog();
    setLoadingGenReport(true);

    ExecutionsService.executeApiV1ExecutionsPost({ requestBody: {} })
      .then((res) => {
        if (res?.execution_id) {
          setLoadingGenReport(false);
          navigate(`/reports/${res?.execution_id}`);
        }
      })
      .catch((e) => {
        showApiError(e);
        setLoadingGenReport(false);
      });
  };

  return (
    <div className={styles.reqGenerateNewReportModalFooter}>
      <Button onClick={onClickGenerateReportHandler}>Generate</Button>
      <Button onClick={closeDialog} severity="secondary">
        Cancel
      </Button>
    </div>
  );
};
