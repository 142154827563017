import { Icon } from 'components';
import React from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';
import { SectionKey, useCheckDetails } from './context';

interface AddCommandButtonProps {
  iterationKey: string;
  sectionKey: SectionKey;
}

export const AddCommandButton = ({
  iterationKey,
  sectionKey,
}: AddCommandButtonProps) => {
  const { addCommand } = useCheckDetails();

  const onShowFormHandler = () => {
    addCommand(iterationKey, sectionKey, {});
  };

  return (
    <div className={styles.addCommandButtonWrapper}>
      <div
        onClick={onShowFormHandler}
        className={cx(styles.addCommandButton, {
          // [styles.disabled]: visibleForm,
        })}
      >
        <Icon className={styles.icon} size={'1.5rem'} name="plus" />
        Add command
      </div>
    </div>
  );
};
