import { format, parseJSON } from 'date-fns';

export function isObjectInArray<T>(arr: T[], obj: T): boolean {
  const objString = JSON.stringify(obj);
  return arr.some((item) => JSON.stringify(item) === objString);
}

export const getDataTime = (str: string) => {
  const date = parseJSON(str);
  const formattedDate = format(date, 'dd/MM/yyyy - hh:mm a');
  return formattedDate;
};
