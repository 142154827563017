import React, { useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useEffectUpdate } from 'hooks';

export interface Tab {
  id: string;
  title: React.ReactNode;
  isActive?: boolean;
  icon?: React.ReactNode;
  onClick?: (
    id: string,
    tabProps?: Tab,
    e?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
}

interface TabsProps {
  tabs: Tab[];
  activeTab?: string;
  onChange?: (
    id: string,
    tabProps?: Tab,
    e?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
}

export const Tabs = ({ tabs, activeTab, onChange }: TabsProps) => {
  const [active, setActiveTab] = useState<Tab>(
    (tabs || []).filter((x) => x.id === activeTab)[0] ||
      (tabs || []).filter((x) => !!x.isActive)[0],
  );

  useEffectUpdate(() => {
    setActiveTab((tabs || []).filter((x) => x.id === activeTab)[0]);
  }, [activeTab]);

  const handleOnClick =
    (x: Tab) => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      setActiveTab(x);
      if (typeof onChange === 'function') {
        onChange(x.id, x, e);
      }
      if (typeof x?.onClick === 'function') {
        x?.onClick(x.id, x, e);
      }
    };

  return (
    <nav className={cx(styles.tabs)}>
      {(tabs || []).map((x, i) => (
        <span
          onClick={handleOnClick(x)}
          key={`chart-tab-item-${i}_${x.id}`}
          className={cx(styles.tabLayout, styles.tabStyle, {
            [styles.active]: x.id === active?.id,
          })}
        >
          {x.icon}
          {x.title}
        </span>
      ))}
    </nav>
  );
};
