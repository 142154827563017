import React, { useEffect, useState } from 'react';
import { RoutePageData } from 'router/shared/models';
import cx from 'classnames';
import styles from './styles.module.scss';
import {
  Icon,
  LoadingDots,
  ResultStatus,
  Tabs,
  usePageContext,
} from 'components';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useLoader } from 'hooks';
import { convertRecord, fetchCheckResultDetails } from '../shared/requests';
import { isEmpty, toCapitalize } from 'shared/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from 'shared/providers/dialog/context';
import {
  RequestRecommendationsModal,
  RequestRecommendationsModalFooter,
} from '../modals/request-recommendations.modal';
import { CheckDetailsProvider } from './context/privider';
import { useCheckDetails } from './context';
import { CheckDetailsTabView } from './details-tab';
import { TroubleshootTabView } from './troubleshoot-tab';
import { AccordionTabChangeEvent } from 'primereact/accordion';
import { filterCommandsByCondition, mapRecommendations } from '../shared/utils';
import { AccordionsList } from './accordions-list';
import { ApiError, ChecksService } from 'shared/api/client';
import { LoadingAccordingList } from './loading';
import { useCurrentReport } from '../current-report/context';
import {
  ResetRecommendationsModal,
  ResetRecommendationsModalFooter,
} from '../modals/reset-recommendations.modal';
import { useToast } from 'shared/providers/toast/context';
import { ProgressSpinner } from 'primereact/progressspinner';

interface Params extends Record<string, string> {
  executionId: string;
  checkResultId: string;
}

const FailedCheckDetailsView = () => {
  const { error404 } = usePageContext();
  const [activeTab, setActiveTab] = useState<string>('troubleshoot');
  const [activeIndexes, setActiveIndexes] = useState<{
    [key: string]: number[] | number | null;
  } | null>(null);
  const [currentIteration, setCurrentIteration] =
    useState<string>('System diagnostics');
  const [visibleLeft, setVisibleLeft] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState<{
    [key: string]: boolean;
  }>();

  const [loadingReRun, setReRunLoading] = useState<boolean>(false);
  const [loadingReset, setResetLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const {
    addIteration,
    addIterations,
    removeIteration,
    setData,
    details,
    iterations,
  } = useCheckDetails();
  const { showApiError } = useToast();
  const { updateReport } = useCurrentReport();

  const { executionId, checkResultId } = useParams<Params>();

  const errorHandler = (e: ApiError) => {
    if (e.status === 404) {
      error404();
    }
  };

  const loadedData = useLoader(fetchCheckResultDetails, errorHandler);

  useEffect(() => {
    if (!isEmpty(loadedData)) {
      setData({
        details: loadedData,
        iterations: {
          'System diagnostics': {
            isExpanded: true,
            id: 'system_diagnostics',
            data: {
              Summary: {
                type: 'summary',
                isExpanded: true,
                value: loadedData?.explanation || '',
              },
              Diagnostics: {
                isExpanded: true,
                type: 'commands',
                commands:
                  filterCommandsByCondition(
                    loadedData?.executedCommands,
                    (x) => !x.recommendationId,
                  ) || {},
              },
            },
          },
          ...mapRecommendations(
            loadedData?.recommendations || [],
            loadedData?.executedCommands || {},
          ),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedData]);

  const handleOnChangeTab = (id: string) => {
    setActiveTab(id);
  };

  const handleRequestRecommendations = () => {
    openDialog({
      header: 'Request recommendations',
      footer: (
        <RequestRecommendationsModalFooter
          executionId={executionId}
          navigate={navigate}
          checkResultId={checkResultId}
          updateReport={updateReport}
          iterations={iterations}
          addIteration={addIteration}
          removeIteration={removeIteration}
          addIterations={addIterations}
          setLoading={setLoadingRequest}
          setCurrentIteration={setCurrentIteration}
        />
      ),
      content: <RequestRecommendationsModal />,
    });
  };

  const handleReRun = () => {
    if (checkResultId) {
      setReRunLoading(true);
      ChecksService.rerunCheckApiV1ChecksCheckResultIdReRunPost({
        checkResultId,
      })
        .then((res) => {
          const mappedData = {
            id: res?.id,
            objectName: res?.object_name,
            checkTitle: res?.check_title,
            severity: res?.severity,
            status: res?.status,
            namespace: res?.object_meta['resource_namespace'] || '',
            explanation: res?.explanation,
            executedCommands: convertRecord(res?.executed_commands || {}),
            recommendations: res?.recommendations,
          };
          setData({
            details: mappedData,
            iterations: {
              'System diagnostics': {
                id: 'system_diagnostics',
                isExpanded: true,
                data: {
                  Summary: {
                    type: 'summary',
                    isExpanded: true,
                    value: mappedData?.explanation,
                  },
                  Diagnostics: {
                    type: 'commands',
                    isExpanded: true,
                    commands:
                      filterCommandsByCondition(
                        mappedData?.executedCommands,
                        (x) => !x.recommendationId,
                      ) || {},
                  },
                },
              },
              ...mapRecommendations(
                mappedData?.recommendations || [],
                mappedData?.executedCommands || {},
              ),
            },
          });
          setReRunLoading(false);
        })
        .catch((e) => {
          setReRunLoading(false);
          showApiError(e);
        });
    }
  };

  const handleResetRecommendations = () => {
    openDialog({
      header: 'Reset recommendations',
      footer: (
        <ResetRecommendationsModalFooter
          executionId={executionId}
          navigate={navigate}
          setData={setData}
          setResetLoading={setResetLoading}
          checkResultId={checkResultId}
        />
      ),
      content: <ResetRecommendationsModal />,
    });
  };

  const handleOnBack = () => {
    navigate(`/reports/${executionId}`);
  };

  const onTabChangeHandler =
    (key: string) => (event: AccordionTabChangeEvent) => {
      setActiveIndexes((prev) => ({ ...prev, [key]: event.index }));
    };

  const countIterations = Object.keys(iterations || {})?.length;

  const disabledRequest = Object.values(loadingRequest || {}).some((x) => !!x);

  const disabledButtons = loadingReRun || loadingReset || disabledRequest;

  return (
    <div className={cx(styles.detailsPage)}>
      <div className={cx(styles.header)}>
        <div className={cx(styles.left)}>
          <div onClick={handleOnBack} className={cx(styles.back)}>
            <Icon size={'1.5rem'} name="chevron_left" />
          </div>
          <h4 className={cx(styles.title)}>{details?.objectName}</h4>
          <ResultStatus type={details?.status}>
            {toCapitalize((details?.status || '')?.toLowerCase())}
          </ResultStatus>
          {loadingReRun && (
            <div className={cx(styles.loadingStatus)}>
              <div className={styles.loading}>
                <ProgressSpinner
                  style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
                />
              </div>
              Re-running check <LoadingDots />
            </div>
          )}
          {loadingReset && (
            <div className={cx(styles.loadingStatus)}>
              <div className={styles.loading}>
                <ProgressSpinner
                  style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
                />
              </div>
              Reset recommendations <LoadingDots />
            </div>
          )}
          {disabledRequest && (
            <div className={cx(styles.loadingStatus)}>
              <div className={styles.loading}>
                <ProgressSpinner
                  style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
                />
              </div>
              Request recommendations <LoadingDots />
            </div>
          )}
        </div>
        {details.status !== 'PASS' && (
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button
              onClick={handleResetRecommendations}
              icon={<Icon size={'1.5rem'} name="reset" />}
              tooltipOptions={{ showOnDisabled: true, position: 'bottom' }}
              severity="secondary"
              disabled={disabledButtons}
              label="Reset recommendations"
            />
            <Button
              onClick={handleRequestRecommendations}
              icon={<Icon size={'1.5rem'} name="AI" />}
              tooltipOptions={{ showOnDisabled: true, position: 'bottom' }}
              severity="secondary"
              disabled={disabledButtons}
              label="Request recommendations"
            />
            <Button
              onClick={handleReRun}
              icon={
                loadingReRun ? (
                  <span className={styles.spinner}>
                    <ProgressSpinner
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        margin: 0,
                      }}
                    />
                  </span>
                ) : (
                  <Icon
                    className={styles.reRunCheckIcon}
                    size={'1.5rem'}
                    name="re-run-check"
                  />
                )
              }
              disabled={disabledButtons}
              tooltipOptions={{ showOnDisabled: true, position: 'bottom' }}
            >
              Re-run check
            </Button>
          </div>
        )}
      </div>
      <div className={cx(styles.contentWrapper, 'scrollable')}>
        {!visibleLeft && (
          <div className={styles.btnConfigWrapper}>
            <div
              onClick={() => setVisibleLeft(true)}
              className={styles.btnConfig}
            >
              <Icon
                size={'1.5rem'}
                className={styles.btnConfigIcon}
                name="sets-of-recommendations"
              />
            </div>
          </div>
        )}
        <Sidebar
          modal={false}
          header={
            <Tabs
              onChange={handleOnChangeTab}
              activeTab={activeTab}
              tabs={[
                {
                  id: 'troubleshoot',
                  icon: <Icon size={'1.5rem'} name="sets-of-recommendations" />,
                  title: 'Troubleshoot',
                },
                {
                  id: 'details',
                  icon: <Icon size={'1.5rem'} name="details" />,
                  title: 'Details',
                },
              ]}
            />
          }
          appendTo={'self'}
          dismissable={false}
          closeOnEscape={false}
          style={{
            top: '9.1rem',
            width: '29.5rem',
            boxShadow: 'none',
            overflow: 'hidden',
            position: 'relative',
            borderRightColor: '#CCD8EA',
          }}
          blockScroll={true}
          visible={visibleLeft}
          position="left"
          onHide={() => setVisibleLeft(false)}
        >
          <div className={cx(styles.sidebarBodyWrap)}>
            <div
              style={{ bottom: '9.1rem' }}
              className={cx(styles.sidebarBody, 'scrollable', 'absolute')}
            >
              {activeTab === 'troubleshoot' && (
                <TroubleshootTabView
                  setCurrentIteration={setCurrentIteration}
                  currentIteration={currentIteration}
                />
              )}
              {activeTab === 'details' && <CheckDetailsTabView />}
            </div>
          </div>
        </Sidebar>
        <div
          style={{ paddingLeft: visibleLeft ? '29.5rem' : '0' }}
          className={cx(styles.content)}
        >
          <div className={cx(styles.body)}>
            {countIterations === 0 ? (
              <LoadingAccordingList message="We are executing diagnostics commands" />
            ) : (
              <AccordionsList
                status={details?.status}
                activeIndexes={activeIndexes}
                iterations={iterations}
                onTabChangeHandler={onTabChangeHandler}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FailedCheckDetailsPage = () => {
  return (
    <CheckDetailsProvider>
      <FailedCheckDetailsView />
    </CheckDetailsProvider>
  );
};

export default {
  title: 'Report',
  pageStyle: 'details',
  route: {
    path: '/reports/:executionId/details/:checkResultId',
    Component: FailedCheckDetailsPage,
  },
} as RoutePageData;
