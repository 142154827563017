import React from 'react';
import { ErrorPage } from 'pages/error-page';
import { RoutePage } from './route-page';
import { RouteObject } from 'react-router';
import {
  NestedRoutes,
  OutletPage,
  Page,
  RoutePageData,
  Routes,
} from '../models';

export class PageManager {
  private _pages: RoutePage[];
  private _rootOutlet: RoutePage | null = null;

  constructor(
    createRoot: boolean,
    private routes: Routes,
  ) {
    this._pages = [];
    if (createRoot) {
      this._rootOutlet = this.addOutlet({
        route: {
          path: '/',
          errorElement: <ErrorPage />,
        },
      });
    } else {
      this._rootOutlet = this.initializeRoutes(this.routes.root, '/');
    }
  }

  private mapRoutes(routes: NestedRoutes, parent: RoutePage) {
    for (const key in routes) {
      if (key === 'index') {
        const indexPage = this.createIndexPage({
          ...routes[key],
          route: { ...(routes[key] as Page).route },
        } as Page);
        parent.addChild(indexPage);
      } else if (Object.hasOwn(routes[key], 'index')) {
        const outlet = this.createOutletPage({
          ...(routes[key] as OutletPage)?.index,
          route: {
            ...(routes[key] as OutletPage)?.index?.route,
            Component: undefined,
          },
        } as Page);
        parent?.addChild(outlet);
        this.mapRoutes(routes[key] as NestedRoutes, outlet);
      } else {
        const page = this.createPage(routes[key] as Page);
        parent.addChild(page);
      }
    }
    return parent;
  }

  private initializeRoutes(
    routes: NestedRoutes,
    basePath: string,
  ): RoutePage | null {
    const rootOutlet = this.addOutlet({
      route: { path: basePath, errorElement: <ErrorPage /> },
    });
    this.mapRoutes(routes, rootOutlet);
    return rootOutlet;
  }

  get root() {
    return this._rootOutlet;
  }

  createPage(routeConfig: RoutePageData) {
    const newPage = new RoutePage(routeConfig);
    return newPage;
  }

  createIndexPage(routeConfig: RoutePageData) {
    const newPage = new RoutePage({
      ...routeConfig,
      route: { ...routeConfig.route, index: true } as RouteObject,
    });
    return newPage;
  }

  createOutletPage(routeConfig: RoutePageData) {
    const newPage = new RoutePage({ ...routeConfig, outlet: true });
    return newPage;
  }

  addPage(routeConfig: RoutePageData) {
    const newPage = this.createPage({
      ...routeConfig,
      route: { ...routeConfig?.route, errorElement: <ErrorPage /> },
    });
    this._pages.push(newPage);
    return newPage;
  }

  addOutlet(routeConfig: RoutePageData) {
    return this.addPage({ ...routeConfig, outlet: true });
  }

  getPages() {
    return this._pages;
  }
}
