import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { APIOptions, PrimeReactProvider } from 'primereact/api';
import { DialogProvider } from 'shared/providers/dialog';
import { ToastProvider } from 'shared/providers/toast';
import { CurrentReportProvider } from 'pages/reports/current-report/context/provider';

function App() {
  const value: Partial<APIOptions> = {
    inputStyle: 'filled',
    ripple: true,
  };

  return (
    <PrimeReactProvider value={value}>
      <ToastProvider>
        <DialogProvider>
          <CurrentReportProvider>
            <RouterProvider
              router={router}
              future={{ v7_startTransition: true }}
            />
          </CurrentReportProvider>
        </DialogProvider>
      </ToastProvider>
    </PrimeReactProvider>
  );
}

export default App;
