import { LoadingDots } from 'components/loading-dots';
import { usePageContext } from 'components/page';
import { useEffectUpdate } from 'hooks';
import { useCurrentReport } from 'pages/reports/current-report/context';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from 'shared/providers/dialog/context';
import { getAssetsPath, normalizeBoolean } from 'shared/utils';
import {
  GenerateNewReportModal,
  GenerateNewReportModalFooter,
} from './modals/generate-new-report.modal';
import { PossibleParams, PossibleState } from './models';
import styles from './styles.module.scss';

export const Header = () => {
  const params = useParams<PossibleParams>();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { getReport } = useCurrentReport();
  const [loadingGenReport, setLoadingGenReport] = useState(false);
  const { state, setState } = usePageContext<PossibleState>();
  const upgradeSubscriptionHandler = () => {
    window.open('https://unskript.com/', '_blank');
  };

  const report = getReport(state?.reportId || params?.executionId);

  // important for current report page
  useEffectUpdate(() => {
    if (state?.isError404) {
      setState((prev) => ({ ...prev, isError404: false, isLoading: true }));
    }
  }, [state, params]);

  const generateReportHandler = () => {
    openDialog({
      header: 'Request Generate New Report',
      footer: (
        <GenerateNewReportModalFooter
          navigate={navigate}
          setPageState={setState}
          setLoadingGenReport={setLoadingGenReport}
        />
      ),
      content: <GenerateNewReportModal />,
    });
  };

  const navigateToHome = () => {
    navigate('/');
  };

  const showUpgradeSubscription = !normalizeBoolean(
    window.__RUNTIME_CONFIG__.AI_ENABLED,
  );

  return (
    <div className={styles.appHeader}>
      <img
        onClick={navigateToHome}
        className={styles.logo}
        src={getAssetsPath('logo')}
        alt="logo"
      />
      <div className={styles.right}>
        {showUpgradeSubscription && (
          <>
            <div className={styles.text}>
              <p className={styles.content}>
                You are using free version of app
              </p>
              <p className={styles.desc}>
                Please upgrade your subscription to use more features
              </p>
            </div>
            <Button
              className={styles.btnUpgrade}
              onClick={upgradeSubscriptionHandler}
            >
              Upgrade Subscription
            </Button>
          </>
        )}

        <Button
          className={styles.btnGenerate}
          disabled={
            loadingGenReport ||
            state?.isLoading ||
            report?.data?.status === 'IN_PROGRESS'
          }
          onClick={generateReportHandler}
        >
          {loadingGenReport ? (
            <div className={styles.loading}>
              <ProgressSpinner
                style={{ width: '1.5rem', height: '1.5rem', margin: 0 }}
              />
              Please wait <LoadingDots />
            </div>
          ) : (
            `Generate new report`
          )}
        </Button>
      </div>
    </div>
  );
};
