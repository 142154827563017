import React from 'react';
import cx from 'classnames';
import { Header } from 'components/header';
import styles from './app.module.scss';

export const PageContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={cx(styles.app, 'p-input-filled')}>
      <Header></Header>
      <div
        id={'mainScrollable'}
        style={{ marginTop: 64, height: '100%' }}
        className={cx(styles.appContainer, 'scrollable')}
      >
        {children}
      </div>
    </div>
  );
};
