/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExecuteRequest } from '../models/ExecuteRequest';
import type { ExecuteResponse } from '../models/ExecuteResponse';
import type { GetExecutionResponse } from '../models/GetExecutionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExecutionsService {
  /**
   * Execute
   * @returns ExecuteResponse Successful Response
   * @throws ApiError
   */
  public static executeApiV1ExecutionsPost({
    requestBody,
  }: {
    requestBody: ExecuteRequest;
  }): CancelablePromise<ExecuteResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/executions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Last Execution
   * @returns GetExecutionResponse Successful Response
   * @throws ApiError
   */
  public static getLastExecutionApiV1ExecutionsLastGet(): CancelablePromise<GetExecutionResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/executions/last',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Execution
   * @returns GetExecutionResponse Successful Response
   * @throws ApiError
   */
  public static getExecutionApiV1ExecutionsExecutionIdGet({
    executionId,
  }: {
    executionId: string;
  }): CancelablePromise<GetExecutionResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/executions/{execution_id}',
      path: {
        execution_id: executionId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
}
