import { Toast, ToastMessage } from 'primereact/toast';
import React, { ReactNode, useRef } from 'react';
import { ApiError } from 'shared/api/client';
import { ToastContext, ToastContextType } from './context';

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const toast = useRef<Toast | null>(null);

  const showToast = (message: ToastMessage | ToastMessage[]) => {
    toast?.current?.show(message);
  };
  const showApiError = (ex: ApiError) => {
    if (ex.status === 504) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.statusText,
        life: 3000,
      });
    } else {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.body.message || ex?.message,
        life: 3000,
      });
    }
  };

  const value: ToastContextType = { showToast, showApiError };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toast position="bottom-right" ref={toast} />
    </ToastContext.Provider>
  );
};
