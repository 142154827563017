// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Obj = Record<string, any>;

interface NormalizeArrayProps<T, K, R> {
  array: T[];
  key: K;
  iterator?: (item: T) => R;
}

export function normalizeArray<T extends Obj, K extends keyof T, R = T>({
  array,
  key,
  iterator,
}: NormalizeArrayProps<T, K, R>): Record<T[K], R> {
  const normalized: Record<T[K], R> = {} as Record<T[K], R>;
  (array || []).forEach((item) => {
    const keyValue = item[key];
    const transformedItem = iterator ? iterator(item) : item;
    normalized[keyValue] = transformedItem as R;
  });
  return normalized;
}
