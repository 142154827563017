import { ErrorPage } from 'pages/error-page';
import React, {
  createContext,
  useState,
  PropsWithChildren,
  useContext,
} from 'react';

export type DefaultPageState<T> = {
  isError404?: boolean;
} & T;

type PageContextState<T> = DefaultPageState<T>;

interface Context<T> {
  state: PageContextState<DefaultPageState<T>>;
  error404: () => void;
  setState: React.Dispatch<React.SetStateAction<T | undefined>>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PageContext = createContext<Context<any>>({
  state: {},
  error404: () => {
    console.log(
      `This method "error404" for page component was called too early, or no provider was found`,
    );
  },
  setState: () => {
    console.log(
      `This method "setState" for page component was called too early, or no provider was found`,
    );
  },
});

export function PageProvider<T>({ children }: PropsWithChildren) {
  const [state, setState] = useState<PageContextState<T>>();

  const error404 = () => {
    setState((prev) => ({ ...prev, isError404: true }) as DefaultPageState<T>);
  };

  return (
    <PageContext.Provider value={{ state, setState, error404 }}>
      {!state?.isError404 ? children : <ErrorPage usePageProvider={false} />}
    </PageContext.Provider>
  );
}

export function usePageContext<T>(): Context<T> {
  const context = useContext(PageContext) as Context<T>;
  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider');
  }
  return context;
}
