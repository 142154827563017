import React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import styles from './styles.module.scss';
import cx from 'classnames';
import { useCurrentRoute } from 'hooks';
import { DataRoute, HandleRoute } from 'shared/models';
import { UIMatch } from 'react-router';
import { PageProvider } from './context';
import { PageContainer } from 'page-container';
export * from './context';

interface PageProps {
  className?: string;
  usePageProvider?: boolean;
  breadcrumbs?: boolean;
  pageStyle?: 'summary' | 'details';
  title?: (
    route?: UIMatch<DataRoute<unknown>, HandleRoute<unknown>>,
  ) => React.ReactNode | false;
}

const DefaultTitle = (route?: UIMatch<unknown, HandleRoute<unknown>>) => {
  if (route?.handle?.title) {
    return <h2 className={styles.titlePage}>{route?.handle?.title}</h2>;
  }
  return <></>;
};

export const Page = ({
  className,
  usePageProvider = true,
  pageStyle = 'summary',
  breadcrumbs = true,
  title = DefaultTitle,
  children,
}: React.PropsWithChildren<PageProps>) => {
  const route = useCurrentRoute();

  const body =
    pageStyle === 'summary' ? (
      <PageContainer>
        <div className={cx(styles.appPage, className)}>
          <div className={cx(styles.header)}>
            <div className={cx(styles.left)}>
              {breadcrumbs && <Breadcrumbs />}
              {title(route)}
            </div>
            <div className={cx(styles.right)}>
              {route?.handle?.headerRightTemplate}
            </div>
          </div>
          {children}
        </div>
      </PageContainer>
    ) : (
      <PageContainer>{children}</PageContainer>
    );

  return usePageProvider ? <PageProvider>{body}</PageProvider> : body;
};
