// React Imports
import { useRef, useEffect, RefObject } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BaseScrollOptions } from './utils/types';
import { DEFAULT_SCROLL_FUNC } from './utils/functions';
import {
  DEFAULT_SCROLL_BEHAVIOR,
  DEFAULT_SCROLL_POSITION,
} from './utils/constants';

export function useHashScroll<T extends HTMLElement>(
  hash: string,
  options: Partial<BaseScrollOptions> = {},
  classNameScrollable = 'scrollable',
): RefObject<T> {
  const { hash: urlHash, pathname } = useLocation();
  const navigate = useNavigate();

  const ref = useRef<T>(null);

  const {
    behavior = DEFAULT_SCROLL_BEHAVIOR,
    position = DEFAULT_SCROLL_POSITION,
    scrollFunc = DEFAULT_SCROLL_FUNC,
  } = options;

  let { requiredPathname } = options;

  if (hash.charAt(0) !== '#') {
    hash = '#' + hash;
  }

  if (typeof requiredPathname === 'string') {
    requiredPathname = [requiredPathname];
  }

  useEffect(() => {
    const handler = () => {
      navigate({ hash: '#' });
    };
    Object.entries(
      document?.getElementsByClassName(classNameScrollable) || {},
    )?.forEach(([key, el]) => {
      el?.addEventListener('scroll', handler);
    });
    return () => {
      Object.entries(
        document?.getElementsByClassName(classNameScrollable) || {},
      )?.forEach(([key, el]) => {
        el?.removeEventListener('scroll', handler);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      urlHash === hash &&
      (requiredPathname === undefined || requiredPathname.includes(pathname))
    ) {
      if (ref.current) {
        scrollFunc<T>(ref, behavior, position);
      }
    }
  }, [
    urlHash,
    ref,
    hash,
    requiredPathname,
    pathname,
    scrollFunc,
    behavior,
    position,
  ]);

  return ref;
}
