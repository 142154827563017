import { useDeepCompareEffect } from 'hooks/use-deep-compare-effect';
import { useEffect, useState } from 'react';
import { Params, useParams, useLocation } from 'react-router';
import { ApiError } from 'shared/api/client';

interface State<T, P extends Readonly<Params<string>>> {
  loadedData?: T;
  params: P;
}

export function useLoaderWithLocation<T, P extends Readonly<Params<string>>>(
  request: (params: Params<string>) => Promise<T>,
  errorCallback?: (e: ApiError) => void,
) {
  const params = useParams();
  const location = useLocation();
  const [state, setState] = useState<State<T, P>>({
    params: params as P,
  });

  useEffect(() => {
    request(params)
      .then((res) =>
        setState((prev) => ({
          ...prev,
          params: params as P,
          loadedData: res,
        })),
      )
      .catch((e) => {
        if (typeof errorCallback === 'function') {
          errorCallback(e);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, params, location]);

  return state;
}

export function useLoader<T>(
  request: (params: Params<string>) => Promise<T>,
  errorCallback?: (e: ApiError) => void,
) {
  const params = useParams();
  const [state, setState] = useState<T>();

  useDeepCompareEffect(() => {
    request(params)
      .then((res) => setState(res))
      .catch((e) => {
        if (typeof errorCallback === 'function') {
          errorCallback(e);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, params]);

  return state;
}
