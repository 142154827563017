import React from 'react';

export const JsonViewer = ({
  id,
  jsonData,
  className,
}: {
  id?: string | null;
  jsonData: string | null;
  className?: string;
}) => {
  // Convert JSON data to a string and split it by lines
  let jsonLines: string[] = [];
  try {
    jsonLines = jsonData?.split('\n') || [];
  } catch (e) {
    console.error(`JsonViewer: Failed to convert JSON data`);
  }

  return (
    <pre
      id={id || undefined}
      className={className}
      style={{
        whiteSpace: 'break-spaces',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineBreak: 'auto',
      }}
    >
      {jsonLines?.map((line, index) => (
        <div key={index} style={{ display: 'flex' }}>
          {/* Display line number */}
          <span style={{ color: 'gray', marginRight: '1.8rem' }}>
            {index + 1}
          </span>
          {/* Display the line of JSON data */}
          <span>{line}</span>
        </div>
      ))}
    </pre>
  );
};
